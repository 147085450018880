import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {HorizontalPosition, VerticalPosition} from '@common/types';
import {TweenMax} from 'gsap';

@Component({
  selector: 'app-arrow-dropdown-container',
  templateUrl: './arrow-dropdown-container.component.html',
  styleUrls: ['./arrow-dropdown-container.component.scss']
})
export class ArrowDropdownContainerComponent implements OnInit {
  @Input() verticalPosition: VerticalPosition = 'up';
  @Input() horizontalPosition: HorizontalPosition = 'right';
  @Input() outerWrapperClass = '';
  @Input() innerWrapperClass = '';

  @Input()
  set isVisible(value: boolean) {
    if (value !== this.currentVisibility) {
      value ? this.animateShow() : this.animateHide();
    }
    this.currentVisibility = value;
  }

  private currentVisibility = false;


  constructor(private element: ElementRef<HTMLElement>) {
  }

  ngOnInit() {
  }

  private animateHide() {
    if (this.element) {
      TweenMax.to(this.element.nativeElement, 0.15, {
        yPercent: -5,
        opacity: 0,
        display: 'none'
      });
    }
  }

  private animateShow() {
    if (this.element) {
      TweenMax.fromTo(this.element.nativeElement, 0.15, {
        yPercent: -5,
        opacity: 0,
      }, {
        yPercent: 0,
        autoAlpha: 1,
        display: 'block'
      });
    }
  }

}
