import {Dispatch} from 'redux';

interface EmitEventAction {
  type: 'EMIT_EVENT';
  event: string;
  data?: any;
}

export type EventEmitterAction = EmitEventAction;

export const emitEvent = (event: string, data = undefined) => async (dispatch: Dispatch) => {
  dispatch<EmitEventAction>({type: 'EMIT_EVENT', event, data});
};
