import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {RootState} from '@store/reducer/root-reducer';
import {Subscription} from 'rxjs';
import {dispatch, validateUserKey} from '@common/helpers';
import {createProjectUsers} from '@store/action/model/user-action';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit, OnDestroy {
  @Input() projectId: string;
  @Input() roleNamePlural: string;
  @Input() roleKey: string;

  private eventSubscription: Subscription;
  private inputText: string;

  constructor(private redux: NgRedux<RootState>) {
  }

  ngOnInit() {
    this.eventSubscription = this.redux.select(s => s.collection.eventEmitter.events).subscribe(value => {
      const event = [...value].pop();
      if (event && event.name === 'SUBMIT_USER_PERMISSIONS') {
        this.commitNewUsers();
      }
    });
  }

  ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }

  commitNewUsers() {
    if (!this.inputText) {
      return; // break silently
    }
    console.log(this.inputText);
    const userKeys = this.inputText.replace(/ /g, '').split(',');
    console.log(userKeys);
    const valid = userKeys.every(validateUserKey);
    if (!valid) {
      // todo handle error
      return;
    }
    dispatch(createProjectUsers(this.projectId, userKeys));
  }
}
