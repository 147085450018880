import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-application-card-section',
  templateUrl: './application-card-section.component.html',
  styleUrls: ['./application-card-section.component.scss']
})
export class ApplicationCardSectionComponent implements OnInit {
  @Input() section: any;
  constructor() { }

  ngOnInit() {
  }

}
