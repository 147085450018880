import {Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TweenMax} from 'gsap';
import {throttle} from 'lodash';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NgRedux} from '@angular-redux/store';
import {RootState} from '@common/store/reducer/root-reducer';

@Component({
  selector: 'app-slide-up-container',
  templateUrl: './slide-up-container.component.html',
  styleUrls: ['./slide-up-container.component.scss']
})
export class SlideUpContainerComponent implements OnInit, OnDestroy {
  @Input() header: string;
  @Input() label: string;
  @Input() expandedHeader: string;
  @Input() expandedLabel: string;
  @Input() slideDuration = 0.3;
  @Input() iconRotateDuration = 0.2;
  @Input() hideCompletely = false;

  private $destroy: Subject<boolean> = new Subject<boolean>();

  @ViewChild('slideContainer', {static: false}) element: ElementRef<HTMLDivElement>;
  @ViewChild('icon', {static: false}) icon: ElementRef<HTMLDivElement>;

  private pinnedTop = false;
  private bottomPadding = 0;
  private headerHeight = 75;

  private selectedWorkspaces;

  private onSwipeUp = throttle((e) => {
    if (!this.pinnedTop) {
      this.slideUp();
    }
  });

  private onSwipeDown = throttle((e) => {
    if (this.pinnedTop) {
      this.slideDown();
    }
  });

  private onClick = throttle((e) => {
    if (this.pinnedTop) {
      this.slideDown();
    } else {
      this.slideUp();
    }
  });

  constructor(private redux: NgRedux<RootState>, private zone: NgZone) {
  }

  ngOnInit() {
    this.redux.select(s => s.collection.selectedWorkspaces).pipe(
      takeUntil(this.$destroy)
    )
      .subscribe(value => {
        this.selectedWorkspaces = value;
      });

    this.expandedHeader = this.expandedHeader || this.header;
    this.expandedLabel = this.expandedLabel || this.label;
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
  }

  public slideUp() {
    TweenMax.to(this.element.nativeElement, this.slideDuration, {
      // ease: Back.easeOut.config(2),
      y: -this.element.nativeElement.clientHeight + (this.headerHeight),
      onComplete: () => {
        this.pinnedTop = true;
      }
    });
    TweenMax.to(this.icon.nativeElement, this.iconRotateDuration, {
      rotation: 180
    });
  }

  public slideDown() {
    TweenMax.to(this.element.nativeElement, this.slideDuration, {
      // ease: Back.easeOut.config(2),
      y: 0,
      onComplete: () => {
        this.pinnedTop = false;
      }
    });
    TweenMax.to(this.icon.nativeElement, this.iconRotateDuration, {
      rotation: 360
    });
  }
}
