import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {TweenMax} from 'gsap';
import {dispatch} from '@common/helpers';
import {deleteProjectUser} from '@store/action/model/user-action';

@Component({
  selector: 'app-authorized-user',
  templateUrl: './authorized-user.component.html',
  styleUrls: ['./authorized-user.component.scss']
})
// todo replace with model after backend mod
export class AuthorizedUserComponent implements OnInit {
  @Input() id: string;
  @Input() user: string;
  @Input() projectId: string;
  @Input() animateIndex = -1;

  constructor(private element: ElementRef<HTMLElement>) {
  }

  ngOnInit() {
    if (this.animateIndex >= 0) {
      this.animate();
    } else {
      this.show();
    }
  }

  animate() {
    TweenMax.fromTo(this.element.nativeElement, 0.3, {
      xPercent: 25,
      autoAlpha: 0,
      display: 'block'
    }, {
      xPercent: 0,
      autoAlpha: 1,
      delay: 0.1 * this.animateIndex
    });
  }

  show() {
    TweenMax.set(this.element.nativeElement, {display: 'block', autoAlpha: 1});
  }

  delete() {
    TweenMax.to(this.element.nativeElement, 0.4, {
      xPercent: 25,
      opacity: 0,
      onComplete: () => {
        dispatch(deleteProjectUser(this.projectId, this.id));
      }
    });
  }
}
