import {applyMiddleware, combineReducers, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import adminsReducer from '@store/reducer/model/admin-reducer';
import productReducer from '@store/reducer/model/product-reducer';
import userReducer from '@store/reducer/model/user-reducer';
import {
  AdminModelState,
  EventEmitterState,
  ProductModelState,
  SelectedApplicationCollectionState,
  UserModelState,
  WorkspaceModelState
} from '@interface/state';
import selectedApplicationReducer from '@store/reducer/collection/selected-application-reducer';
import workspaceReducer from '@store/reducer/model/workspace-reducer';
import eventEmitterReducer from '@store/reducer/collection/event-emitter-reducer';

export interface RootState {
  model: {
    admins: AdminModelState,
    products: ProductModelState,
    users: UserModelState,
    workspaces: WorkspaceModelState
  };
  collection: {
    selectedWorkspaces: SelectedApplicationCollectionState,
    eventEmitter: EventEmitterState
  };
}

const rootReducer = combineReducers<RootState>({
  model: combineReducers({
    admins: adminsReducer,
    products: productReducer,
    users: userReducer,
    workspaces: workspaceReducer
  }),
  collection: combineReducers({
    selectedWorkspaces: selectedApplicationReducer,
    eventEmitter: eventEmitterReducer
  })
});

export const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(thunk)
));
