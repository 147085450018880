import {Component, Input, OnInit} from '@angular/core';
import {dispatch} from '@common/helpers';
import {loadProjectUsers} from '@store/action/model/user-action';

@Component({
  selector: 'app-user-manage-main',
  templateUrl: './user-manage-main.component.html',
  styleUrls: ['./user-manage-main.component.scss']
})
export class UserManageMainComponent implements OnInit {
  @Input()
  set projectId(value: string) {
    this.projectId$ = value;
    this.reload();
  }

  private projectId$: string;

  constructor() {
  }

  ngOnInit(): void {
  }

  private reload() {
    dispatch(loadProjectUsers(this.projectId$));
  }
}
