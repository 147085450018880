import {AfterContentChecked, Component, ElementRef, HostBinding, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-toolbar-button',
  templateUrl: './toolbar-button.component.html',
  styleUrls: ['./toolbar-button.component.scss']
})
export class ToolbarButtonComponent implements OnInit, AfterContentChecked {
  @Input() icon;
  @ViewChild('wrapper', {static: true}) wrapper: ElementRef<HTMLDivElement>;
  @HostBinding('class.exists') existsClass = false;

  constructor() {
  }

  ngOnInit() {
  }

  checkExistence() {
    this.existsClass = this.icon || this.wrapper && this.wrapper.nativeElement.childNodes.length > 0;
  }

  ngAfterContentChecked(): void {
    this.checkExistence();
  }
}
