import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-action-toolbar',
  templateUrl: './action-toolbar.component.html',
  styleUrls: ['./action-toolbar.component.scss']
})
export class ActionToolbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
