import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-application-users-page',
  templateUrl: './application-users.component.html',
  styleUrls: ['./application-users.component.scss']
})
export class ApplicationUsersComponent implements OnInit, OnDestroy {
  private projectId: string;
  private routeSubscription: Subscription;

  constructor(private route: ActivatedRoute, private zone: NgZone) {
  }

  ngOnInit() {
    this.routeSubscription = this.route.paramMap.subscribe(value => {
      this.zone.run(() => {
        this.projectId = value.get('projectId');
      });
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

}
