import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {RootState} from '@store/reducer/root-reducer';
import {ApplicationModel} from '@interface/models';
import {ReplaySubject, Subscription} from 'rxjs';
import {dispatch} from '@common/helpers';
import {loadProductWorkspace} from '@store/action/model/workspace-action';
import {transformWorkspaceToApplication} from '@common/transformers';

@Component({
  selector: 'app-application-main',
  templateUrl: './application-main.component.html',
  styleUrls: ['./application-main.component.scss']
})
export class ApplicationMainComponent implements OnInit, OnDestroy {
  @Input()
  set applicationId(value: string) {
    this.applicationId$ = value;
    this.reloadWorkspace();
  }

  @Input()
  set productId(value: string) {
    this.productId$ = value;
    this.reloadWorkspace();
  }

  private productId$: string;
  private applicationId$: string;
  private application = new ReplaySubject<ApplicationModel>();
  private workspaceSubscription: Subscription;

  constructor(private redux: NgRedux<RootState>, private zone: NgZone) {
  }

  ngOnInit() {
    this.workspaceSubscription = this.redux.select(s => s.model.workspaces).subscribe(value => {
      if (value.status.error['READ_WORKSPACES']) {
        // todo handle error
        return;
      }
      if (value.status.done.includes('READ_WORKSPACES') && value.items[this.productId$]) {
        console.log(transformWorkspaceToApplication(value.items[this.productId$], this.applicationId$));
        this.application.next(transformWorkspaceToApplication(value.items[this.productId$], this.applicationId$));
      }
    });
  }

  ngOnDestroy(): void {
    this.workspaceSubscription.unsubscribe();
  }

  private reloadWorkspace() {
    if (!this.productId$) {
      return;
    }

    dispatch(loadProductWorkspace(this.productId$, true));
  }
}
