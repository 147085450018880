import {Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TweenMax} from 'gsap';
import {Subscription} from 'rxjs';
import {NgRedux} from '@angular-redux/store';
import {RootState} from '@store/reducer/root-reducer';

@Component({
  selector: 'app-select-action',
  templateUrl: './action-select.component.html',
  styleUrls: ['./action-select.component.scss']
})
export class ActionSelectComponent implements OnInit, OnDestroy {
  @ViewChild('chevron', {static: true, read: ElementRef}) chevronDown: ElementRef<HTMLElement>;

  private dropdownVisible = false;
  private selfIsVisible = false;
  private selectedWorkspacesSubscription: Subscription;

  constructor(private redux: NgRedux<RootState>, private zone: NgZone, private elementRef: ElementRef<HTMLElement>) {
  }

  ngOnInit() {
    this.selectedWorkspacesSubscription = this.redux.select(s => s.collection.selectedWorkspaces)
      .subscribe(value => value.applicationIds.length > 0 ? this.animateShow() : this.animateHide());
  }

  ngOnDestroy(): void {
    this.selectedWorkspacesSubscription.unsubscribe();
  }

  private animateHide() {
    if (this.elementRef && this.selfIsVisible) {
      TweenMax.to(this.elementRef.nativeElement, 0.15, {
        x: 30,
        autoAlpha: 0
      });
      this.selfIsVisible = false;
    }
  }

  private animateShow() {
    if (this.elementRef && !this.selfIsVisible) {
      TweenMax.fromTo(this.elementRef.nativeElement, 0.15, {
        x: 30,
        autoAlpha: 0
      }, {
        x: 0,
        autoAlpha: 1
      });
      this.selfIsVisible = true;
    }
  }

  private animateMouseEnter() {
    if (this.chevronDown) {
      TweenMax.to(this.chevronDown.nativeElement, 0.1, {
        rotation: 180,
        onComplete: () => {
          this.zone.run(() => {
            this.dropdownVisible = true;
          });
        }
      });
    }
  }

  private animateMouseExit() {
    if (this.chevronDown) {
      TweenMax.to(this.chevronDown.nativeElement, 0.1, {
        rotation: 0,
        onComplete: () => {
          this.zone.run(() => {
            this.dropdownVisible = false;
          });
        }
      });
    }
  }

}
