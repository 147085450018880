import {Pipe, PipeTransform} from '@angular/core';
import {numberFormat} from '@common/helpers';

@Pipe({
  name: 'numberFormatPipe'
})
export class NumberFormatPipePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return numberFormat(value as string);
  }

}
