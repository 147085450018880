import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {TweenMax} from 'gsap';

@Directive({
  selector: '[appFadeInInit]'
})
export class FadeInInitDirective implements OnInit {

  @Input() apply = true;

  constructor(private element: ElementRef<HTMLElement>) {
  }

  ngOnInit(): void {
    TweenMax.to(this.element.nativeElement, 1, {
      opacity: 0,
      display: 'block'
    });
  }

}
