import {Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {TimelineMax} from 'gsap';
import {RootState} from '@store/reducer/root-reducer';
import {Subscription} from 'rxjs';
import {dispatch} from '@common/helpers';
import {loadProductWorkspace} from '@store/action/model/workspace-action';
import {SelectedApplicationCollectionState, WorkspaceModelState} from '@interface/state';
import {transformWorkspaceToApplication} from '@common/transformers';
import {WorkspacesStoreModel} from '@interface/store-models';
import {resetSelectedApplication} from '@store/action/collection/selected-applications-action';
import {after} from 'lodash';
import {emitEvent} from '@store/action/collection/event-emitter-action';

@Component({
  selector: 'app-workspace-grid',
  templateUrl: './workspace-grid.component.html',
  styleUrls: ['./workspace-grid.component.scss']
})
export class WorkspaceGridComponent implements OnInit, OnDestroy {

  @Input()
  set productId(value: string) {
    this.productId$ = value;
    this.reload();
  }

  constructor(private redux: NgRedux<RootState>, private zone: NgZone) {
  }

  @ViewChild('container', {static: false}) container: ElementRef<HTMLDivElement>;

  private cardsAnimated = false;
  private shouldAnimate = false;
  private productId$; // TODO resolve with router
  private workspace: WorkspacesStoreModel;
  private workspaceState: WorkspaceModelState;
  private workspaceSubscription: Subscription;
  private selectedWorkspaces: string[] = [];
  private selectedWorkspacesSubscription: Subscription;
  private eventSubscription: Subscription;
  private objectKeys = Object.keys;
  private transformWorkspace = transformWorkspaceToApplication;

  private animateCards = after(4, () => {
    if (this.cardsAnimated || !this.container) {
      return;
    }
    console.log('AA: animation invoked');
    this.cardsAnimated = true;
    const animation = this.animation();
    animation.timeScale(2);
    animation.call(() => dispatch(emitEvent('BACKGROUND_ANIMATION_COMPLETE')));
    animation.play();
  });

  ngOnInit() {
    this.shouldAnimate = localStorage.getItem('enter-animation') === 'done';
    if (!this.redux.getState().model.workspaces.status.done.includes('READ_WORKSPACES')) {
      dispatch(loadProductWorkspace(this.productId$));
    }
    this.workspaceSubscription = this.redux.select(s => s.model.workspaces).subscribe(value => {
      this.updateWorkspaceState(value);
    });
    this.selectedWorkspacesSubscription = this.redux.select(s => s.collection.selectedWorkspaces).subscribe(value => {
      this.updateSelectedWorkspacesState(value);
    });
    this.eventSubscription = this.redux.select(s => s.collection.eventEmitter.events).subscribe(value => {
      const event = value.filter(i => i.name === 'BACKGROUND_ANIMATION_CARD_TRIGGER').length > 0;
      const lastEvent = [...value].pop();
      if (event) {
        this.eventSubscription.unsubscribe();
        this.animateCards();
      }
      if (lastEvent && lastEvent.name === 'BACKGROUND_ANIMATION_COMPLETE') {
        this.zone.run(() => this.shouldAnimate = true);
      }
    });
  }

  ngOnDestroy(): void {
    this.workspaceSubscription.unsubscribe();
    this.selectedWorkspacesSubscription.unsubscribe();
  }

  private updateWorkspaceState(workspaceState: WorkspaceModelState) {
    if (workspaceState.status.error['READ_WORKSPACES']) {
      // todo handle error
      return;
    }

    if (workspaceState.status.done.includes('READ_WORKSPACES') && workspaceState.items[this.productId$]) {
      this.zone.run(() => {
        this.workspaceState = workspaceState;
        this.reload();
        this.animateCards();
      });
    }
  }

  private updateSelectedWorkspacesState(selectedWorkspaceState: SelectedApplicationCollectionState) {
    this.zone.run(() => {
      this.selectedWorkspaces = selectedWorkspaceState.applicationIds;
    });
  }

  private isWorkspaceSelected(workspaceId: string) {
    return this.selectedWorkspaces.includes(workspaceId);
  }

  private reload(tryFetch = true) {
    if (this.workspaceState && this.workspaceState.items[this.productId$]) {
      this.zone.run(() => {
        if (!this.workspace || this.workspace.productId !== this.productId$) {
          const animation = this.animation();
          animation.timeScale(4.5);
          animation.eventCallback('onReverseComplete', () => {
            this.zone.run(() => {
              dispatch(resetSelectedApplication());
              this.workspace = this.workspaceState.items[this.productId$];
              console.log(this.workspace.productId);
            });
            animation.play();
          }).reverse(0);
        }
      });
    } else if (tryFetch) {
      dispatch(loadProductWorkspace(this.productId$));
      this.reload(false);
    }
    console.log(this.workspaceState);
  }

  private animation() {
    const timeline = new TimelineMax({delay: 0, paused: true});
    if (this.container) {
      const elements = this.container.nativeElement.childNodes;
      timeline.staggerFromTo(elements, 0.9, {
        yPercent: 40,
        opacity: 0
      }, {
        yPercent: 0,
        opacity: 0.88,
        onComplete: () => localStorage.setItem('enter-animation', 'done')
      }, 0.25, 'stagger');
    }

    return timeline;
  }
}
