import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

export type HoverState = 'leave' | 'enter';

@Directive({
  selector: '[appHoverListener]'
})
export class HoverListenerDirective {
  @Output() mouseHover = new EventEmitter<HoverState>();

  constructor() {
  }

  @HostListener('mouseenter', ['$event'])
  private onMouseEnter() {
    this.mouseHover.emit('enter');
  }

  @HostListener('mouseleave', ['$event'])
  private onMouseLeave() {
    this.mouseHover.emit('leave');
  }
}
