import {UsersModel} from '@interface/models';

export const SampleUsers: UsersModel = {
  q459191: 'Nimmy Nemlin (FG-360) with build manager role',
  q471831: 'q471831 with build manager role',
  q463094: 'Alexander Tolonics (FG-550) with build manager role',
  q274138: 'Martin Huber-Reger (FG-441) with build manager role',
  q406814: 'Anett Eikermann (FG-433) with build manager role',
  q158684: 'Juergen Skerhut (FG-65) with build manager role',
  q193088: 'Walter Dellian (FG-510) with build manager role',
  q284264: 'Stefan Hueber (FG-351) with build manager role',
  q438658: 'Dimitri Stelmakh (DE-821) with build manager role',
  q358858: 'Maximilian Kaltner (FG-450) with build manager role',
  q468984: 'q468984 with build manager role',
  q257103: 'Markus Dieterle (FG-251) with build manager role',
  q336271: 'Florence Bellendir (FG-651) with build manager role',
  q458852: 'Mathis Bareis (FG-630) with build manager role',
  q454146: 'Andreas Guentner (FG-312) with build manager role',
  q293921: 'Michael Hund (FG-251) with build manager role',
  q455673: 'Anna Medvedeva (FG-340) with build manager role',
  q220008: 'Sabine Ham (FG-341) with build manager role',
  q293646: 'Kalyan Koora (EE-901) with build manager role',
  qdatc03: 'QDATC03 DebugUser with build manager role',
  q407399: 'Dominic Bortmes (FG-250) with build manager role',
  qtb8926: 'qtb8926 with build manager role',
  q374310: 'Alexander Yeo (FG-331) with build manager role',
  q101096: 'Andrew Scoones (FG-4-I-1) with build manager role',
  q073146: 'Charalambos Achmanoglou (FG-251) with build manager role',
  q282013: 'Hristo Tsonkov (FG-323) with build manager role',
  q047756: 'Helmut Kerscher (TG-380) with build manager role',
  q348747: 'Hans-Peter Mohl (FG-314) with build manager role',
  q367465: 'Dhyan Blum (FG-630) with build manager role',
  q452775: 'Christina Gaesslein (FG-251) with build manager role',
  q214565: 'Bernhard Hennlich (DE-811) with build manager role',
  q384801: 'Bjoern-Erik Wenz (FG-3-I-1) with build manager role',
  q446693: 'Jonas Kiesecker (FG-742MU) with build manager role',
  q297311: 'Juergen Bobinger (FG-251) with build manager role',
  qxx0490: 'qxx0490 with build manager role',
  q395307: 'Fabian Vierke (FG-433) with build manager role',
  q247044: 'Oliver Klein (FG-1-P) with build manager role',
  q267155: 'Jakob Gajdzik (FG-66) with build manager role',
  q237017: 'Andre Kisters (FG-641) with build manager role',
  q452662: 'Henrik Andersson (FG-250) with build manager role',
  q074808: 'Klaus Grasl (FG-841) with build manager role',
  q171164: 'Robert Demmel (FG-64) with build manager role',
  q291837: 'Stefan Eberling (FG-430) with build manager role',
  q260161: 'Ugur Kircaoglu (FG-143) with build manager role',
  q378617: 'Ursula Wessely (FG-541) with build manager role',
  q455170: 'Verena Julia Diebold (FG-251) with build manager role',
  qxtesttest: 'qxtesttest with build manager role',
  q116676: 'Christian Dreher (FG-8-I-1) with build manager role',
  q398526: 'Amor Jenhani (FG-250) with build manager role',
  q240379: 'Markus Roeckelein (FG-541) with build manager role'
};
