import {Component, Input, OnInit, ViewChild, HostListener} from '@angular/core';
import {ProjectModel} from '@interface/models';
import {SlideUpContainerComponent} from '@app/containers/slide-up-container/slide-up-container.component';
import {dispatch} from '@common/helpers';
import {emitEvent} from '@store/action/collection/event-emitter-action';

@Component({
  selector: 'app-project-table',
  templateUrl: './project-table.component.html',
  styleUrls: ['./project-table.component.scss']
})
export class ProjectTableComponent implements OnInit {
  @Input() projects: ProjectModel[];
  @Input() productId: string;
  screenHeight: number;
  screenWidth: number;
  private actions = false;
  private selectedProject = null;
  private p = 1;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor() {
    this.onResize();
  }

  ngOnInit() {
  }

  private showActions(projectKey, e) {
    dispatch(emitEvent('SHOW_PROJECT_ACTIONS', projectKey));
  }

}
