import {Component, ElementRef, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import {RootState} from '@common/store/reducer/root-reducer';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-action-list',
  templateUrl: './action-list.component.html',
  styleUrls: ['./action-list.component.scss']
})
export class ActionListComponent implements OnInit, OnDestroy {
  @Input() projectKey: string; // todo connect with, once backend supports
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private selectedWorkspaces;

  constructor(private redux: NgRedux<RootState>, private zone: NgZone, private elementRef: ElementRef<HTMLElement>) {
  }

  ngOnInit() {
    this.redux.select(s => s.collection.selectedWorkspaces).pipe(
      takeUntil(this.$destroy)
    )
    .subscribe(value => {
      console.log(value);
      this.selectedWorkspaces = value;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.unsubscribe();
  }

}
