import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, HostListener} from '@angular/core';
import {TweenMax} from 'gsap';
import {HoverState} from '@app/directives/hover-listener.directive';

@Component({
  selector: 'app-header-button',
  templateUrl: './header-button.component.html',
  styleUrls: ['./header-button.component.scss']
})
export class HeaderButtonComponent implements OnInit, AfterViewInit {
  @ViewChild('bottomBar', {static: false, read: ElementRef}) bottomBar: ElementRef<HTMLDivElement>;
  private propActive = false;
  private hover: HoverState = 'leave';
  screenHeight: number;
  screenWidth: number;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor() {
  }

  get active() {
    return this.propActive;
  }

  @Input()
  set active(active: boolean) {
    this.propActive = active;
    this.hover = active ? 'enter' : 'leave';
    this.triggerAnimation();
  }

  ngAfterViewInit(): void {
    this.triggerAnimation();
  }

  private triggerAnimation() {
    if (this.bottomBar) {
      const {nativeElement} = this.bottomBar;
      TweenMax.to(nativeElement, 0.2, {
        scaleX: this.propActive || this.hover === 'enter' ? 0.95 : 0
      });
    }
  }

  private onHover(hover: HoverState) {
    if (this.screenWidth > 760) {
      this.hover = hover;
      this.triggerAnimation();
    }
  }

  ngOnInit() {}

}
