import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {SlideUpContainerComponent} from '@app/containers/slide-up-container/slide-up-container.component';
import {NgRedux} from '@angular-redux/store';
import {RootState} from '@store/reducer/root-reducer';

@Component({
  selector: 'app-application-page',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit, OnDestroy {
  @ViewChild('slider', {static: false})
  slideUp: SlideUpContainerComponent;

  private applicationId: string;
  private productId: string;
  private routeSubscription: Subscription;
  private eventSubscription: Subscription;
  private slideUpProjectKey = '';

  constructor(private route: ActivatedRoute, private zone: NgZone, private redux: NgRedux<RootState>) {
  }

  ngOnInit() {
    this.routeSubscription = this.route.paramMap.subscribe(value => {
      this.zone.run(() => {
        this.applicationId = value.get('applicationId');
        this.productId = value.get('productId');
      });
    });
    this.eventSubscription = this.redux.select(s => s.collection.eventEmitter.events).subscribe(value => {
      const event = [...value].pop();
      console.log(value);
      console.log(event);
      if (event && event.name === 'SHOW_PROJECT_ACTIONS' && this.slideUp) {
        this.slideUpProjectKey = event.data as string;
        this.slideUp.slideUp();
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.eventSubscription.unsubscribe();
  }
}
