import {EventEmitterState} from '@interface/state';
import {EventEmitterAction} from '@store/action/collection/event-emitter-action';

const INITIAL_STATE: EventEmitterState = {
  events: []
};

const eventEmitterReducer = (state: EventEmitterState = INITIAL_STATE, action: EventEmitterAction): EventEmitterState => {
  switch (action.type) {
    case 'EMIT_EVENT':
      return {
        ...state,
        events: [...state.events.slice(-4), {data: action.data, name: action.event}]
      };
    default:
      return state;
  }
};

export default eventEmitterReducer;
