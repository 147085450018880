import {AfterContentInit, Component, NgZone} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {slider} from '@app/app.transition.animation';
import {NgRedux} from '@angular-redux/store';
import {RootState} from '@store/reducer/root-reducer';
import {Location} from '@angular/common';
import {emitEvent} from '@store/action/collection/event-emitter-action';
import {dispatch} from '@common/helpers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slider
  ]
})
export class AppComponent implements AfterContentInit {
  private ready = false;

  constructor(private location: Location, private zone: NgZone, private redux: NgRedux<RootState>) {
  }

  ngAfterContentInit(): void {
    if ([''].includes(this.location.path())) {
      dispatch(emitEvent('BACKGROUND_ANIMATION_START', {animate: true}));
      this.awaitAnimationComplete();
    } else {
      dispatch(emitEvent('BACKGROUND_ANIMATION_START', {animate: false}));
      this.zone.run(() => this.ready = true);
    }
  }

  awaitAnimationComplete() {
    const subscription = this.redux.select(s => s.collection.eventEmitter.events).subscribe(value => {
      const event = [...value].pop();
      console.log(event);
      if (event && event.name === 'BACKGROUND_ANIMATION_COMPLETE') {
        this.zone.run(() => this.ready = true);
        subscription.unsubscribe();
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
