import {Component, Input, NgZone, OnDestroy, OnInit, HostListener} from '@angular/core';
import {HoverState} from '@app/directives/hover-listener.directive';
import {NgRedux} from '@angular-redux/store';
import {RootState} from '@store/reducer/root-reducer';
import {ReplaySubject, Subscription} from 'rxjs';
import {dispatch} from '@common/helpers';
import {loadProducts} from '@store/action/model/product-action';
import {ActivatedRoute} from '@angular/router';
import {ProductModelState} from '@interface/state';

@Component({
  selector: 'app-header-product-item',
  templateUrl: './header-product-item.component.html',
  styleUrls: ['./header-product-item.component.scss']
})
export class HeaderProductItemComponent implements OnInit, OnDestroy {
  @Input() headerActive = false;

  private screenHeight: number;
  private screenWidth: number;

  public dropdownVisible = false;
  public productState: ProductModelState;
  public selectedProductId = new ReplaySubject<string>();
  private productSubscription: Subscription;
  private routeSubscription: Subscription;
  private objectValues = Object.values;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  constructor(private zone: NgZone, private redux: NgRedux<RootState>, private route: ActivatedRoute) {
    this.onResize();
  }

  ngOnInit() {
    this.productSubscription = this.redux.select(s => s.model.products).subscribe(value => {
      if (value.status.error['READ_PRODUCTS']) {
        // todo handle error
        return;
      }
      if (value.status.done.includes('READ_PRODUCTS') && value.items) {
        this.zone.run(() => {
          this.productState = value;
        });
      }
    });
    this.routeSubscription = this.route.paramMap.subscribe(value => {
      const productId = value.get('productId');
      this.selectedProductId.next(productId);
    });
    dispatch(loadProducts(true));
  }

  ngOnDestroy(): void {
    this.productSubscription.unsubscribe();
  }

  handleHover(hover: HoverState) {
    if (this.screenWidth > 760) {
      this.zone.run(() => this.dropdownVisible = hover === 'enter');
    } else {
      if (hover !== 'enter') {
        this.zone.run(() => this.dropdownVisible = false);
      }
    }
  }

  closeDropdown(e) {
    if (this.screenWidth <= 760) {
      this.zone.run(() => this.dropdownVisible = !this.dropdownVisible);
    }
    console.log(this.headerActive);
  }

}
