import {FetchingState} from '@interface/state';
import {Action} from 'redux';

export interface FetchingAction extends Action {
  error?: string;
}

export const fetchingReducer = (name: string, operation = 'READ') => (state: FetchingState, action: FetchingAction): FetchingState => {
  const namespace = `${operation}_${name}`;
  const {[namespace]: value, ...reducedError} = state.status.error;

  switch (action.type) {
    case `START_${namespace}`:
      return {
        ...state,
        status: {
          pending: [...state.status.pending, namespace],
          done: state.status.done.filter(i => i !== namespace),
          error: reducedError
        }
      };
    case `DONE_${namespace}`:
      return {
        ...state,
        status: {
          pending: state.status.pending.filter(i => i !== namespace),
          done: [...state.status.done, namespace],
          error: reducedError
        }
      };
    case `ERROR_${namespace}`:
      return {
        ...state,
        status: {
          done: state.status.pending.filter(i => i !== namespace),
          pending: state.status.done.filter(i => i !== namespace),
          error: {
            ...reducedError,
            [namespace]: action.error
          }
        }
      };
    default:
      return state;
  }
};
