import {Component, Input, OnInit} from '@angular/core';
import {dispatch} from '@common/helpers';
import {emitEvent} from '@store/action/collection/event-emitter-action';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit {
  @Input() projectId: string;

  constructor() {
  }

  ngOnInit() {
  }

  submit() {
    dispatch(emitEvent('SUBMIT_USER_PERMISSIONS'));
  }

}
