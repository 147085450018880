import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {NgRedux} from '@angular-redux/store';
import {RootState} from '@common/store/reducer/root-reducer';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-product-page',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {
  private $destroy: Subject<boolean> = new Subject<boolean>();
  private routeSubscription: Subscription;
  private productId: string;
  private selectedWorkspaces = [];
  private actions = false;
  private selectedProject = null;

  constructor(private redux: NgRedux<RootState>, private route: ActivatedRoute, private zone: NgZone) {
  }

  ngOnInit() {
    this.routeSubscription = this.route.paramMap.subscribe(value => {
      this.zone.run(() => {
        this.productId = value.get('productId');
      });
    });

    this.redux.select(s => s.collection.selectedWorkspaces.applicationIds).pipe(
      takeUntil(this.$destroy)
    )
      .subscribe(value => {
        this.zone.run(() => {
          this.selectedWorkspaces = value;
        });
      });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
}
