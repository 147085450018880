import {Dispatch} from 'redux';


interface ToggleApplication {
  type: 'TOGGLE_SELECTED_APPLICATION';
  applicationId: string;
}

interface ResetApplication {
  type: 'RESET_SELECTED_APPLICATION';
}

export type SelectedApplicationCollectionAction = ToggleApplication | ResetApplication;

export const toggleSelectedApplication = (applicationId: string) => (dispatch: Dispatch) => {
  dispatch<SelectedApplicationCollectionAction>({type: 'TOGGLE_SELECTED_APPLICATION', applicationId});
};

export const resetSelectedApplication = () => (dispatch: Dispatch) => {
  dispatch<SelectedApplicationCollectionAction>({type: 'RESET_SELECTED_APPLICATION'});
};
