import {Component, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {Orientation} from '@common/types';

@Component({
  selector: 'app-separator',
  templateUrl: './separator.component.html',
  styleUrls: ['./separator.component.scss']
})
export class SeparatorComponent implements OnInit {

  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  ngOnInit() {
  }

  @Input()
  set orientation(orientation: Orientation) {
    const orientations: Orientation[] = ['horizontal', 'vertical'];
    orientations.forEach(i => this.renderer.removeClass(this.elementRef.nativeElement, i));
    this.renderer.addClass(this.elementRef.nativeElement, orientation);
  }
}
