import {Component, OnInit} from '@angular/core';
import {AnimationOptions, BMEnterFrameEvent} from 'ngx-lottie';
import {AnimationItem} from 'lottie-web';
import {NgRedux} from '@angular-redux/store';
import {RootState} from '@store/reducer/root-reducer';
import {dispatch} from '@common/helpers';
import {emitEvent} from '@store/action/collection/event-emitter-action';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-default-background',
  templateUrl: './default-background.component.html',
  styleUrls: ['./default-background.component.scss']
})
export class DefaultBackgroundComponent implements OnInit {
  private lottieConfig: AnimationOptions = {
    autoplay: false,
    loop: false,
    path: 'assets/lottie/background.json',
    renderer: 'svg',
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  private animationItem: AnimationItem;
  private eventSubscription: Subscription;
  private animationCallback: () => void;

  constructor(private redux: NgRedux<RootState>) {
  }

  ngOnInit() {
    this.eventSubscription = this.redux.select(s => s.collection.eventEmitter.events).subscribe(value => {
      const lastEvent = [...value].pop();
      if (lastEvent && lastEvent.name === 'BACKGROUND_ANIMATION_START') {
        this.animate(lastEvent.data.animate);
      }
    });
  }

  created(item: AnimationItem) {
    this.animationItem = item;
  }

  animate(animation: boolean) {
    if (animation && !localStorage.getItem('enter-animation')) {
      let eventFired = false;
      this.animationCallback = () => {
        this.animationItem.addEventListener('enterFrame', (e: BMEnterFrameEvent) => {
          if (!eventFired && e.currentTime / e.totalTime > 0.5) {
            dispatch(emitEvent('BACKGROUND_ANIMATION_CARD_TRIGGER'));
            eventFired = true;
          }
        });
        this.animationItem.play();
      };
    } else {
      this.animationCallback = () => {
        const frames = this.animationItem.getDuration(true);
        this.animationItem.goToAndStop(frames - 1, true);
        dispatch(emitEvent('BACKGROUND_ANIMATION_COMPLETE'));
      };
    }
  }

  ready() {
    this.animationCallback();
  }
}
