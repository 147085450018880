import {Component, Input, OnInit} from '@angular/core';
import {HorizontalPosition} from '@common/types';
import {assetExists} from '@common/helpers';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-icon-heading',
  templateUrl: './icon-heading.component.html',
  styleUrls: ['./icon-heading.component.scss']
})
export class IconHeadingComponent implements OnInit {
  @Input() iconPosition: HorizontalPosition;
  @Input() htmlAfter: string;

  @Input()
  set icon(value: string) {
    this.icon$ = value;
    this.iconExistsObservable = assetExists(value);
  }

  private iconExistsObservable: Observable<boolean>;
  private icon$: string;

  constructor() {
  }

  ngOnInit() {
  }

}
