import {animate, group, query, style, transition, trigger} from '@angular/animations';
import {routeOrder} from '@app/app-routing.module';

export const slider =
  trigger('routeAnimations',
    (() => {
      const asd = routeOrder.map(order => {
        const other = routeOrder.filter(i => i !== order);
        return other.map(comparator =>
          transition(
            `${order} => ${comparator}`,
            slideTo(routeOrder.indexOf(order) > routeOrder.indexOf(comparator) ? 'left' : 'right')
          )
        );
      }).reduce((a, b) => a.concat(b));
      console.log(asd);
      return asd;
    })()
  );

function slideTo(direction, amount = '5', duration: number = 0.3) {
  const optional = {optional: true};
  const directionSign = direction === 'left' ? '-' : '';
  const directionSignInverse = direction === 'left' ? '' : '-';

  return [
    query(':enter, :leave', [
      style({
        transform: 'translateX(0)',
        opacity: 1,
        position: 'absolute',
        top: 0
      })
    ], optional),
    query(':enter', [
      style({transform: `translateX(${directionSign}${amount}%)`, opacity: 0})
    ]),
    group([
      query(':leave', [
        animate(`${duration}s ease`, style({transform: `translateX(${directionSignInverse}${amount}%)`, opacity: 0}))
      ], optional),
      query(':enter', [
        animate(`${duration}s ${duration / 1.3}s ease`, style({transform: `translateX(0)`, opacity: 1}))
      ])
    ]),
  ];
}


