import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
  private searchText$ = '';

  @Input() pattern;
  @Input() showIcon: boolean;
  @Input() placeholder = '';
  @Output() searchTextChange = new EventEmitter();

  @Input()
  set searchText(value: string) {
    this.searchText$ = value;
    this.searchTextChange.emit(this.searchText$);
  }

  get searchText() {
    return this.searchText$;
  }

  constructor() {
  }

  ngOnInit() {
    console.log('Show Icon ' + this.showIcon);
  }

}
