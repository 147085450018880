import * as numeral from 'numeral';
import {NgRedux} from '@angular-redux/store';
import axios from 'axios';
import {BehaviorSubject, Observable} from 'rxjs';

export const fakeFetch = async <T>(data: T, timeout: number = 50): Promise<T> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(data);
    }, timeout);
  });
};

export const pipeReducers = (f1, ...fns) => (state, action) =>
  fns.reduce((res, fn) => fn(res, action), f1(state, action));

export const dispatch = (action: any) => {
  NgRedux.instance.dispatch(action);
};

// Sort alphabetically when calling
export const storeKey = (key1, ...keys) => keys.reduce((res, key) => `${res}-${key}`, key1);

const defaultNumeralFormat = '0,0';
export const numberFormat = (value: number | string): string => numeral(value).format(defaultNumeralFormat);

export const assetExists = (path: string): Observable<boolean> => {
  const subject = new BehaviorSubject(false);
  axios.get(path).then(() => subject.next(true)).catch(e => {});
  return subject.asObservable();
};

export const validateUserKey = (key: string) => key.match(/q\d{6}/);
