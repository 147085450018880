import {ProductModelState} from '@interface/state';
import {ProductAction} from '@store/action/model/product-action';
import {fetchingReducer} from '@store/shared/fetching-reducer';
import {SampleProducts} from '@sample/sample-products';
import {pipeReducers} from '@common/helpers';

const INITIAL_STATE: ProductModelState = {
  items: {
    all: SampleProducts[0]
  },
  status: {
    pending: [],
    done: [],
    error: {}
  }
};

const productsModelReducer = (state: ProductModelState = INITIAL_STATE, action: ProductAction): ProductModelState => {
  switch (action.type) {
    case 'ADD_PRODUCTS':
      return {
        ...state,
        items: {
          ...state.items,
          ...action.data
        }
      };
    default:
      return state;
  }
};

const productReducer = pipeReducers(
  productsModelReducer,
  fetchingReducer('PRODUCTS', 'READ')
);

export default productReducer;
