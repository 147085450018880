import {ProductModel} from '@interface/models';
import {Dispatch} from 'redux';
import {FetchingAction} from '@store/shared/fetching-reducer';
import {fakeFetch, storeKey} from '@common/helpers';
import {SampleProducts} from '@sample/sample-products';
import {RootState} from '@store/reducer/root-reducer';

interface AddProductsAction {
  type: 'ADD_PRODUCTS';
  data: {
    [id: string]: ProductModel;
  };
}

export type ProductAction = AddProductsAction;

export const loadProducts = (force: boolean = false) => async (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState().model.products.items;
  if (Object.keys(state).length > 0 && !force) {
    return;
  }

  try {
    dispatch<FetchingAction>({type: 'START_READ_PRODUCTS'});
    const data = await fakeFetch(SampleProducts);
    const model = {};
    data.forEach(i => model[storeKey(i.id)] = i);
    dispatch<AddProductsAction>({type: 'ADD_PRODUCTS', data: model});
    dispatch<FetchingAction>({type: 'DONE_READ_PRODUCTS'});
  } catch (e) {
    dispatch<FetchingAction>({type: 'ERROR_READ_PRODUCTS', error: e});
  }
};
