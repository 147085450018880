import {Component, ElementRef, HostBinding, OnInit, ViewChild} from '@angular/core';
import {TweenMax} from 'gsap';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss']
})
export class HeaderTopComponent implements OnInit {
  @HostBinding('class.container-fluid') containerClass = true;
  @ViewChild('menuItems', {static: true}) menuItems: ElementRef<HTMLDivElement>;

  private showMobileMenu = false;

  constructor() { }

  ngOnInit() {
  }

  private toggleMenu() {
    this.showMobileMenu = !this.showMobileMenu;
    TweenMax.to(this.menuItems.nativeElement, 0.3, {
      x: this.showMobileMenu? 0 : this.menuItems.nativeElement.clientWidth
    });
    console.log(this.showMobileMenu);
  }

}
