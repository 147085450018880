import {SelectedApplicationCollectionState} from '@interface/state';
import {SelectedApplicationCollectionAction} from '@store/action/collection/selected-applications-action';
import {toggleItemArray} from '@common/immutable-helpers';
import {pipeReducers} from '@common/helpers';

const INITIAL_STATE: SelectedApplicationCollectionState = {
  applicationIds: []
};

const selectedWorkspacesCollectionReducer = (state: SelectedApplicationCollectionState = INITIAL_STATE,
                                             action: SelectedApplicationCollectionAction): SelectedApplicationCollectionState => {
  switch (action.type) {
    case 'TOGGLE_SELECTED_APPLICATION':
      return {
        ...state,
        applicationIds: toggleItemArray(state.applicationIds, action.applicationId)
      };
    case 'RESET_SELECTED_APPLICATION':
        return {
          ...state,
          applicationIds: []
        };
    default:
      return state;
  }
};

const selectedApplicationReducer = pipeReducers(
  selectedWorkspacesCollectionReducer
);

export default selectedApplicationReducer;
