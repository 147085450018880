import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {storeKey} from '@common/helpers';
import {NgRedux} from '@angular-redux/store';
import {RootState} from '@store/reducer/root-reducer';

@Component({
  selector: 'app-authorize-user-table',
  templateUrl: './authorize-user-table.component.html',
  styleUrls: ['./authorize-user-table.component.scss']
})
export class AuthorizeUserTableComponent implements OnInit, OnDestroy {
  @Input() projectId;

  private objectKeys = Object.keys;
  private userSubscription: Subscription;
  private users = {};
  private newUserKey = [];

  constructor(private redux: NgRedux<RootState>, private zone: NgZone) {
  }

  ngOnInit() {
    this.userSubscription = this.redux.select(s => s.model.users).subscribe(value => {
      const key = storeKey(this.projectId);
      if (value.status.error['READ_USERS']) {
        // todo handle error
        return;
      }
      if (value.status.pending.includes('CREATE_USERS') && value.items[key]) {
        this.zone.run(() => {
          const users = value.items[key].data;
          const oldUserKeys = Object.keys(this.users);
          this.newUserKey = Object.keys(users).filter(i => !oldUserKeys.includes(i));
          this.users = users;
        });
      } else if (value.status.done.includes('READ_USERS') && value.items[key]) {
        this.zone.run(() => {
          this.users = value.items[key].data;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  animateIndex(key: string) {
    const index = this.newUserKey.indexOf(key);
    if (index >= 0) {
      return this.newUserKey.length - index;
    }
    return index;
  }
}
