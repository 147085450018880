import {ApplicationModel} from '@interface/models';
import {WorkspacesStoreModel} from '@interface/store-models';

export const transformWorkspaceToApplication = (workspaces: WorkspacesStoreModel, id: string): ApplicationModel => ({
  id, projects: workspaces.data[id], productId: workspaces.productId
});

/*export const transformUsersToUsersStore = (projectId: UsersModel, projectKey: string): UsersStoreModel => {
  const model: UsersStoreModel = {projectKey, data: []};
  Object.keys(projectId).forEach(key => {
    model.data.push({id: key, role: projectId[key]});
  });

  return model;
};*/
