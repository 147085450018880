import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProductComponent} from '@app/page/product/product.component';
import {ApplicationComponent} from '@app/page/application/application.component';
import {ApplicationUsersComponent} from '@app/page/application-users/application-users.component';


const routes: Routes = [
  {path: '', redirectTo: 'product/all', pathMatch: 'full'},
  {path: 'product/:productId', component: ProductComponent, data: {animation: 'Product'}},
  {path: 'product/:productId/application/:applicationId', component: ApplicationComponent, data: {animation: 'Application'}},
  {path: 'project/:projectId/users', component: ApplicationUsersComponent, data: {animation: 'ApplicationUsers'}}
];

export const routeOrder = ['Product', 'Application', 'ApplicationUsers'];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
