import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {OutlineData} from '@app/directives/outline-active.directive';
import {ApplicationModel} from '@interface/models';
import {dispatch} from '@common/helpers';
import {toggleSelectedApplication} from '@store/action/collection/selected-applications-action';

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss']
})
export class ApplicationCardComponent implements OnInit {
  @ViewChild('container', {static: true}) container: ElementRef<HTMLDivElement>;
  @Input() application: ApplicationModel;
  @Input() applicationSelected: boolean;

  private outlineOptions: OutlineData = {
    width: '2px',
    style: 'solid',
    color: {
      active: 'rgba(198, 39, 46, 1)',
      inactive: 'rgba(198, 39, 46, 0)'
    }
  };

  constructor() {
  }

  ngOnInit() {
  }

  sectionCondition() {
    return false;
  }

  togglePlatform() {
    dispatch(toggleSelectedApplication(this.application.id));
  }
}
