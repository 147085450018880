import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {HorizontalPosition, VerticalPosition} from '@common/types';

@Directive({
  selector: '[appArrowContainer]'
})
export class ArrowContainerDirective implements OnInit {
  @Input() verticalPosition: VerticalPosition = 'up';
  @Input() horizontalPosition: HorizontalPosition = 'right';
  @Input() offset = '0px'; // Offset for fixing container inside container

  @Input() crossWidth = '1.3rem';
  @Input() crossHeight = '0.65em';
  @Input() crossOffset = '1rem';

  constructor(private ref: ElementRef<HTMLElement>) {
  }

  ngOnInit(): void {
    this.setOffset();
  }


  private setOffset(): void {
    // @ts-ignore
    this.ref.nativeElement.style.clipPath = this.ref.nativeElement.style.webkitClipPath = this.getClipPath();
  }

  private getClipPath(): string {
    const {crossHeight, crossWidth, crossOffset, offset} = this;

    switch (`${this.verticalPosition}-${this.horizontalPosition}`) {
      case 'down-left':
        return `polygon(
            0% 0%,
            100% 0%,
            100% calc(100% - ${crossHeight}),
            calc(${crossOffset} - ${offset} + ${crossWidth}) calc(100% - ${crossHeight}),
            calc(${crossOffset} - ${offset} + ${crossWidth} / 2) 100%,
            calc(${crossOffset} - ${offset}) calc(100% - ${crossHeight}),
            0% calc(100% - ${crossHeight})
        )`;
      case 'down-right':
        return `polygon(
            0% 0%,
            100% 0%,
            100% calc(100% - ${crossHeight}),
            calc(100% - ${crossOffset} + ${offset})  calc(100% - ${crossHeight}),
            calc(100% - ${crossOffset} + ${offset} - ${crossWidth} / 2) 100%,
            calc(100% - ${crossOffset} + ${offset} - ${crossWidth}) calc(100% - ${crossHeight}),
            0% calc(100% - ${crossHeight})
        )`;
      case 'up-left':
        return `polygon(
          0% ${crossHeight},
          calc(${crossOffset} - ${offset}) ${crossHeight},
          calc(${crossOffset} - ${offset} + ${crossWidth} / 2) 0%,
          calc(${crossOffset} - ${offset} + ${crossWidth}) ${crossHeight},
          100% ${crossHeight},
          100% 100%,
          0% 100%,
          0% 0%
        )`;
      case 'up-right':
      default:
        return `polygon(
          0% ${crossHeight},
          calc(100% - ${crossOffset} + ${offset} - ${crossWidth}) ${crossHeight},
          calc(100% - ${crossOffset} + ${offset} - ${crossWidth} / 2) 0%,
          calc(100% - ${crossOffset} + ${offset}) ${crossHeight},
          100% ${crossHeight},
          100% 100%,
          0% 100%,
          0% 0%
        )`;
    }
  }
}
