import {ProductModel} from '@interface/models';

export const SampleProducts: ProductModel[] = [
  {
    id: 'SWP-84',
    name: 'Planning & Execution E/E Verification',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Planning & Execution E/E Verification (Product SWP-84)'
  },
  {
    id: 'SWP-87',
    name: 'FAMOS RAM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'FAMOS RAM (Product SWP-87)'
  },
  {
    id: 'SWP-90',
    name: 'SW Delivery Pipeline',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'SW Delivery Pipeline (Product SWP-90)'
  },
  {
    id: 'SWP-91',
    name: 'TopGear RAM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'TopGear RAM (Product SWP-91)'
  },
  {
    id: 'SWP-93',
    name: 'General E/E Development Topics',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'General E/E Development Topics (Product SWP-93)'
  },
  {
    id: 'SWP-94',
    name: 'Shared RAM Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Shared RAM Services (Product SWP-94)'
  },
  {
    id: 'SWP-96',
    name: 'Aftersales Parts Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Aftersales Parts Data (Product SWP-96)'
  },
  {
    id: 'SWP-97',
    name: 'AS Parts Reporting',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'AS Parts Reporting (Product SWP-97)'
  },
  {
    id: 'SWP-98',
    name: 'Aftersales Price Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Aftersales Price Data (Product SWP-98)'
  },
  {
    id: 'SWP-99',
    name: 'Attendance and Workforce Planning',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Attendance and Workforce Planning (Product SWP-99)'
  },
  {
    id: 'SWP-100',
    name: 'Driving Assistance',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Driving Assistance (Product SWP-100)'
  },
  {
    id: 'SWP-109',
    name: 'Websites - Inspire to Commit (BMW & MINI)',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Websites - Inspire to Commit (BMW & MINI) (Product SWP-109)'
  },
  {
    id: 'SWP-121',
    name: 'Interaction Mgmt',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Interaction Mgmt (Product SWP-121)'
  },
  {
    id: 'SWP-128',
    name: 'B2C Energy Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'B2C Energy Services (Product SWP-128)'
  },
  {
    id: 'SWP-131',
    name: 'Shared Retail Collections',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Shared Retail Collections (Product SWP-131)'
  },
  {
    id: 'SWP-132',
    name: 'TopGear CF',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'TopGear CF (Product SWP-132)'
  },
  {
    id: 'SWP-133',
    name: 'Regulated EU CF',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Regulated EU CF (Product SWP-133)'
  },
  {
    id: 'SWP-134',
    name: 'Regulated AM CF',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Regulated AM CF (Product SWP-134)'
  },
  {
    id: 'SWP-137',
    name: 'Compute',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Compute (Product SWP-137)'
  },
  {
    id: 'SWP-138',
    name: 'Configuration Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Configuration Services (Product SWP-138)'
  },
  {
    id: 'SWP-139',
    name: 'Application Credit Risk Rating',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Application Credit Risk Rating (Product SWP-139)'
  },
  {
    id: 'SWP-143',
    name: 'Automotive Security',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Automotive Security (Product SWP-143)'
  },
  {
    id: 'SWP-152',
    name: 'Customer Data Mgmt',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Customer Data Mgmt (Product SWP-152)'
  },
  {
    id: 'SWP-153',
    name: 'Data Governance & Data Exchange',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Governance & Data Exchange (Product SWP-153)'
  },
  {
    id: 'SWP-156',
    name: 'Customer and Vehicle Oriented Retail Services (CaVORS)',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Customer and Vehicle Oriented Retail Services (CaVORS) (Product SWP-156)'
  },
  {
    id: 'SWP-157',
    name: 'Personal Portal',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Personal Portal (Product SWP-157)'
  },
  {
    id: 'SWP-162',
    name: 'Data Center',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Center (Product SWP-162)'
  },
  {
    id: 'SWP-168',
    name: 'Regulated EU RAM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Regulated EU RAM (Product SWP-168)'
  },
  {
    id: 'SWP-169',
    name: 'Dragon RAM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Dragon RAM (Product SWP-169)'
  },
  {
    id: 'SWP-170',
    name: 'Dealer Management Systems',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Dealer Management Systems (Product SWP-170)'
  },
  {
    id: 'SWP-171',
    name: 'Dealer Performance Steering',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Dealer Performance Steering (Product SWP-171)'
  },
  {
    id: 'SWP-175',
    name: 'Commerce',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Commerce (Product SWP-175)'
  },
  {
    id: 'SWP-180',
    name: 'E/E Function Offerings & Architecture',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'E/E Function Offerings & Architecture (Product SWP-180)'
  },
  {
    id: 'SWP-184',
    name: 'Engineering Change Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Engineering Change Management (Product SWP-184)'
  },
  {
    id: 'SWP-186',
    name: 'SAP Platform',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'SAP Platform (Product SWP-186)'
  },
  {
    id: 'SWP-188',
    name: 'SW Development/App Development',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'SW Development/App Development (Product SWP-188)'
  },
  {
    id: 'SWP-197',
    name: 'Industrial Workspace',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Industrial Workspace (Product SWP-197)'
  },
  {
    id: 'SWP-201',
    name: 'Geometrical Development and Data Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Geometrical Development and Data Management (Product SWP-201)'
  },
  {
    id: 'SWP-203',
    name: 'Hardware Verification',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Hardware Verification (Product SWP-203)'
  },
  {
    id: 'SWP-210',
    name: 'Individual Vehicle',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Individual Vehicle (Product SWP-210)'
  },
  {
    id: 'SWP-211',
    name: 'Industrialization',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Industrialization (Product SWP-211)'
  },
  {
    id: 'SWP-215',
    name: 'Integration Solutions',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Integration Solutions (Product SWP-215)'
  },
  {
    id: 'SWP-220',
    name: 'IT Partner Mgmt',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'IT Partner Mgmt (Product SWP-220)'
  },
  {
    id: 'SWP-224',
    name: 'IT Service Desk',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'IT Service Desk (Product SWP-224)'
  },
  {
    id: 'SWP-227',
    name: 'Language & Translation',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Language & Translation (Product SWP-227)'
  },
  {
    id: 'SWP-228',
    name: 'Technical Data: Light Weight, Materials, Wheel & Tire, Powertrain Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Technical Data: Light Weight, Materials, Wheel & Tire, Powertrain Data (Product SWP-228)'
  },
  {
    id: 'SWP-232',
    name: 'Mainframe',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Mainframe (Product SWP-232)'
  },
  {
    id: 'SWP-233',
    name: 'Content Distribution & Optimization',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Content Distribution & Optimization (Product SWP-233)'
  },
  {
    id: 'SWP-235',
    name: 'Vehicle Interaction Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Vehicle Interaction Services (Product SWP-235)'
  },
  {
    id: 'SWP-238',
    name: 'Mobile Network',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Mobile Network (Product SWP-238)'
  },
  {
    id: 'SWP-239',
    name: 'Multi Project Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Multi Project Management (Product SWP-239)'
  },
  {
    id: 'SWP-241',
    name: 'Network Access',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Network Access (Product SWP-241)'
  },
  {
    id: 'SWP-242',
    name: 'Functional Development and Data Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Functional Development and Data Management (Product SWP-242)'
  },
  {
    id: 'SWP-303',
    name: 'Software Logistics',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Software Logistics (Product SWP-303)'
  },
  {
    id: 'SWP-304',
    name: 'Storage',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Storage (Product SWP-304)'
  },
  {
    id: 'SWP-245',
    name: 'Official Technical Data & Emission & Consumption',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Official Technical Data & Emission & Consumption (Product SWP-245)'
  },
  {
    id: 'SWP-246',
    name: 'On Demand Mobility Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'On Demand Mobility Services (Product SWP-246)'
  },
  {
    id: 'SWP-248',
    name: 'Autonomation',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Autonomation (Product SWP-248)'
  },
  {
    id: 'SWP-250',
    name: 'Regulated AM RAM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Regulated AM RAM (Product SWP-250)'
  },
  {
    id: 'SWP-253',
    name: 'Owners Manual',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Owners Manual (Product SWP-253)'
  },
  {
    id: 'SWP-257',
    name: 'Personnel Data and Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Personnel Data and Services (Product SWP-257)'
  },
  {
    id: 'SWP-258',
    name: 'Physical Wiring System',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Physical Wiring System (Product SWP-258)'
  },
  {
    id: 'SWP-261',
    name: 'Price Master Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Price Master Data (Product SWP-261)'
  },
  {
    id: 'SWP-306',
    name: 'Sourcing',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Sourcing (Product SWP-306)'
  },
  {
    id: 'SWP-307',
    name: 'Supply Chain Execution',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Supply Chain Execution (Product SWP-307)'
  },
  {
    id: 'SWP-263',
    name: 'Product Line Reporting and Steering',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Product Line Reporting and Steering (Product SWP-263)'
  },
  {
    id: 'SWP-264',
    name: 'Product Master Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Product Master Data (Product SWP-264)'
  },
  {
    id: 'SWP-265',
    name: 'Sales Product Master Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Sales Product Master Data (Product SWP-265)'
  },
  {
    id: 'SWP-267',
    name: 'Product Structure, E-BOM, Releaseprocess',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Product Structure, E-BOM, Releaseprocess (Product SWP-267)'
  },
  {
    id: 'SWP-270',
    name: 'Purchase Order',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Purchase Order (Product SWP-270)'
  },
  {
    id: 'SWP-271',
    name: 'Quality Core Processes',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Quality Core Processes (Product SWP-271)'
  },
  {
    id: 'SWP-279',
    name: 'Qualification & HR @ Customer Touchpoints',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Qualification & HR @ Customer Touchpoints (Product SWP-279)'
  },
  {
    id: 'SWP-280',
    name: 'Regulated AM RCM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Regulated AM RCM (Product SWP-280)'
  },
  {
    id: 'SWP-308',
    name: 'Supply Chain Planning',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Supply Chain Planning (Product SWP-308)'
  },
  {
    id: 'SWP-281',
    name: 'TopGear RCM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'TopGear RCM (Product SWP-281)'
  },
  {
    id: 'SWP-282',
    name: 'Regulated EU RCM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Regulated EU RCM (Product SWP-282)'
  },
  {
    id: 'SWP-283',
    name: 'Customer Touchpoint @ Retail',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Customer Touchpoint @ Retail (Product SWP-283)'
  },
  {
    id: 'SWP-284',
    name: 'Process Mining and Robotic Automization',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Process Mining and Robotic Automization (Product SWP-284)'
  },
  {
    id: 'SWP-288',
    name: 'SAP Security & Compliance',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'SAP Security & Compliance (Product SWP-288)'
  },
  {
    id: 'SWP-291',
    name: 'Series@M',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Series@M (Product SWP-291)'
  },
  {
    id: 'SWP-293',
    name: 'Aftersales Service Offers Mgmt.',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Aftersales Service Offers Mgmt. (Product SWP-293)'
  },
  {
    id: 'SWP-295',
    name: 'Service Portal(s)',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Service Portal(s) (Product SWP-295)'
  },
  {
    id: 'SWP-309',
    name: 'Talent Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Talent Management (Product SWP-309)'
  },
  {
    id: 'SWP-310',
    name: 'Targets & Requirements',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Targets & Requirements (Product SWP-310)'
  },
  {
    id: 'SWP-311',
    name: 'Technical Vehicle Information',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Technical Vehicle Information (Product SWP-311)'
  },
  {
    id: 'SWP-313',
    name: 'Test Drive Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Test Drive Management (Product SWP-313)'
  },
  {
    id: 'SWP-316',
    name: 'Type Approval Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Type Approval Management (Product SWP-316)'
  },
  {
    id: 'SWP-318',
    name: 'Used Vehicle Mgmt & Remarketing',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Used Vehicle Mgmt & Remarketing (Product SWP-318)'
  },
  {
    id: 'SWP-320',
    name: 'Remote Vehicle Configuration Mgmt',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Remote Vehicle Configuration Mgmt (Product SWP-320)'
  },
  {
    id: 'SWP-321',
    name: 'Vehicle Data Collection',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Vehicle Data Collection (Product SWP-321)'
  },
  {
    id: 'SWP-322',
    name: 'Vehicle Data Connectivity',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Vehicle Data Connectivity (Product SWP-322)'
  },
  {
    id: 'SWP-323',
    name: 'Vehicle Invoicing',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Vehicle Invoicing (Product SWP-323)'
  },
  {
    id: 'SWP-324',
    name: 'Vehicle Lifecycle Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Vehicle Lifecycle Data (Product SWP-324)'
  },
  {
    id: 'SWP-326',
    name: 'Retail Vehicle Management (incl. Test Drive)',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Retail Vehicle Management (incl. Test Drive) (Product SWP-326)'
  },
  {
    id: 'SWP-327',
    name: 'Verification Planning & Approval',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Verification Planning & Approval (Product SWP-327)'
  },
  {
    id: 'SWP-328',
    name: 'Solutions in Hardware',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Solutions in Hardware (Product SWP-328)'
  },
  {
    id: 'SWP-330',
    name: 'Warehouse Mgmt',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Warehouse Mgmt (Product SWP-330)'
  },
  {
    id: 'SWP-332',
    name: 'Web',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Web (Product SWP-332)'
  },
  {
    id: 'SWP-333',
    name: 'Web Platform',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Web Platform (Product SWP-333)'
  },
  {
    id: 'SWP-334',
    name: 'Work Environment & Health',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Work Environment & Health (Product SWP-334)'
  },
  {
    id: 'SWP-1631',
    name: 'Logistic Planning',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Logistic Planning (Product SWP-1631)'
  },
  {
    id: 'SWP-1636',
    name: 'Material and Process Analysis',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Material and Process Analysis (Product SWP-1636)'
  },
  {
    id: 'SWP-1640',
    name: 'Validation and Integration E/E, driving dynamics, powertrain',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Validation and Integration E/E, driving dynamics, powertrain (Product SWP-1640)'
  },
  {
    id: 'SWP-1641',
    name: 'Validation and Integration Complete Vehicle',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Validation and Integration Complete Vehicle (Product SWP-1641)'
  },
  {
    id: 'SWP-1644',
    name: 'Body Shop and Surface Planning',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Body Shop and Surface Planning (Product SWP-1644)'
  },
  {
    id: 'SWP-1645',
    name: 'Assembly & Inspection Planning, Industrial Engineering & Ergonomic Evaluation',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Assembly & Inspection Planning, Industrial Engineering & Ergonomic Evaluation (Product SWP-1645)'
  },
  {
    id: 'SWP-1646',
    name: 'Forming Planning & CAQ MDM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Forming Planning & CAQ MDM (Product SWP-1646)'
  },
  {
    id: 'SWP-1696',
    name: 'Real Estate Management, Corporate Security',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Real Estate Management, Corporate Security (Product SWP-1696)'
  },
  {
    id: 'SWP-1699',
    name: 'Goods Receipt',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Goods Receipt (Product SWP-1699)'
  },
  {
    id: 'SWP-1705',
    name: 'Inbound Transport Logistics and Container Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Inbound Transport Logistics and Container Management (Product SWP-1705)'
  },
  {
    id: 'SWP-1709',
    name: 'Packaging Plants specific Processes',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Packaging Plants specific Processes (Product SWP-1709)'
  },
  {
    id: 'SWP-1710',
    name: 'Production Planning Internal Manufacturers',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Production Planning Internal Manufacturers (Product SWP-1710)'
  },
  {
    id: 'SWP-1721',
    name: 'Cyber Security Production',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Cyber Security Production (Product SWP-1721)'
  },
  {
    id: 'SWP-1723',
    name: 'Production Control internal Manufacturers',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Production Control internal Manufacturers (Product SWP-1723)'
  },
  {
    id: 'SWP-1724',
    name: 'Production Quality internal Manufacturers',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Production Quality internal Manufacturers (Product SWP-1724)'
  },
  {
    id: 'SWP-1787',
    name: 'Retail CRM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Retail CRM (Product SWP-1787)'
  },
  {
    id: 'SWP-1752',
    name: 'Program Planning and Capacity Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Program Planning and Capacity Management (Product SWP-1752)'
  },
  {
    id: 'SWP-1753',
    name: 'Central Ordering',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Central Ordering (Product SWP-1753)'
  },
  {
    id: 'SWP-1754',
    name: 'M Reporting & Analytics',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'M Reporting & Analytics (Product SWP-1754)'
  },
  {
    id: 'SWP-1776',
    name: 'Need Oriented Product Identification',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Need Oriented Product Identification (Product SWP-1776)'
  },
  {
    id: 'SWP-1777',
    name: 'Payment',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Payment (Product SWP-1777)'
  },
  {
    id: 'SWP-1778',
    name: 'Platform',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Platform (Product SWP-1778)'
  },
  {
    id: 'SWP-1813',
    name: 'Launch Steering & total vehicle release implementation',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Launch Steering & total vehicle release implementation (Product SWP-1813)'
  },
  {
    id: 'SWP-1882',
    name: 'Customer Analytics',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Customer Analytics (Product SWP-1882)'
  },
  {
    id: 'SWP-1886',
    name: 'OtD Platforms and Templates',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'OtD Platforms and Templates (Product SWP-1886)'
  },
  {
    id: 'SWP-1893',
    name: 'OtD Product and Demand Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'OtD Product and Demand Management (Product SWP-1893)'
  },
  {
    id: 'SWP-1939',
    name: 'Advanced data analytics and AI for Autonomous driving',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Advanced data analytics and AI for Autonomous driving (Product SWP-1939)'
  },
  {
    id: 'SWP-1940',
    name: 'Simulation, Data and Scenariomanagement',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Simulation, Data and Scenariomanagement (Product SWP-1940)'
  },
  {
    id: 'SWP-1981',
    name: 'Manual Warehouse Management and Call off',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Manual Warehouse Management and Call off (Product SWP-1981)'
  },
  {
    id: 'SWP-1982',
    name: 'Automatic Warehouse Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Automatic Warehouse Management (Product SWP-1982)'
  },
  {
    id: 'SWP-1984',
    name: 'Picking and Line Supply',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Picking and Line Supply (Product SWP-1984)'
  },
  {
    id: 'SWP-2048',
    name: 'Customer Order Management and Parts Delivery',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Customer Order Management and Parts Delivery (Product SWP-2048)'
  },
  {
    id: 'SWP-2051',
    name: 'Production & Sourcing BOM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Production & Sourcing BOM (Product SWP-2051)'
  },
  {
    id: 'SWP-2063',
    name: 'Gross Demand Calculation',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Gross Demand Calculation (Product SWP-2063)'
  },
  {
    id: 'SWP-2064',
    name: 'Treasury',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Treasury (Product SWP-2064)'
  },
  {
    id: 'SWP-2082',
    name: 'Assembly',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Assembly (Product SWP-2082)'
  },
  {
    id: 'SWP-2084',
    name: 'Shopfloor Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Shopfloor Management (Product SWP-2084)'
  },
  {
    id: 'SWP-2085',
    name: 'Maintenance',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Maintenance (Product SWP-2085)'
  },
  {
    id: 'SWP-2086',
    name: 'Virtual Commissioning & Test',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Virtual Commissioning & Test (Product SWP-2086)'
  },
  {
    id: 'SWP-2087',
    name: 'Press Shop, Tooling & Production Confirmation Internal Manufacturers',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Press Shop, Tooling & Production Confirmation Internal Manufacturers (Product SWP-2087)'
  },
  {
    id: 'SWP-2088',
    name: 'Paint Shop',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Paint Shop (Product SWP-2088)'
  },
  {
    id: 'SWP-2118',
    name: 'Account Management Connected Car',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Account Management Connected Car (Product SWP-2118)'
  },
  {
    id: 'SWP-2121',
    name: 'Infotainment Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Infotainment Services (Product SWP-2121)'
  },
  {
    id: 'SWP-2122',
    name: 'Journey Assistance',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Journey Assistance (Product SWP-2122)'
  },
  {
    id: 'SWP-2124',
    name: 'Billing & Catalog',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Billing & Catalog (Product SWP-2124)'
  },
  {
    id: 'SWP-2125',
    name: 'B2B Energy Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'B2B Energy Services (Product SWP-2125)'
  },
  {
    id: 'SWP-2126',
    name: 'Interfaces KITT',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Interfaces KITT (Product SWP-2126)'
  },
  {
    id: 'SWP-2128',
    name: 'Data Visualization & ETL Platform',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Visualization & ETL Platform (Product SWP-2128)'
  },
  {
    id: 'SWP-2130',
    name: 'Integration HW/SW Assembly',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Integration HW/SW Assembly (Product SWP-2130)'
  },
  {
    id: 'SWP-2138',
    name: 'User Experience Services and myWorkplace',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'User Experience Services and myWorkplace (Product SWP-2138)'
  },
  {
    id: 'SWP-2227',
    name: 'Quality Management System BMW Group',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Quality Management System BMW Group (Product SWP-2227)'
  },
  {
    id: 'SWP-2143',
    name: 'Telephony Interaction Platform',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Telephony Interaction Platform (Product SWP-2143)'
  },
  {
    id: 'SWP-2144',
    name: 'Case Handling',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Case Handling (Product SWP-2144)'
  },
  {
    id: 'SWP-2145',
    name: 'Self Care Features',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Self Care Features (Product SWP-2145)'
  },
  {
    id: 'SWP-2148',
    name: 'Knowledge Mgmt & Self Care Platform',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Knowledge Mgmt & Self Care Platform (Product SWP-2148)'
  },
  {
    id: 'SWP-2151',
    name: 'Content Production',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Content Production (Product SWP-2151)'
  },
  {
    id: 'SWP-2162',
    name: 'Volume Performance',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Volume Performance (Product SWP-2162)'
  },
  {
    id: 'SWP-2164',
    name: 'Feedback & Complaint Mgmt',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Feedback & Complaint Mgmt (Product SWP-2164)'
  },
  {
    id: 'SWP-2190',
    name: 'Wholesale CRM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Wholesale CRM (Product SWP-2190)'
  },
  {
    id: 'SWP-2201',
    name: 'Communication, Strategy & Compliance',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Communication, Strategy & Compliance (Product SWP-2201)'
  },
  {
    id: 'SWP-2206',
    name: 'Smart Factory Lifecycle Planning',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Smart Factory Lifecycle Planning (Product SWP-2206)'
  },
  {
    id: 'SWP-2207',
    name: 'SAP Analytics Platform',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'SAP Analytics Platform (Product SWP-2207)'
  },
  {
    id: 'SWP-2221',
    name: 'Body Shop',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Body Shop (Product SWP-2221)'
  },
  {
    id: 'SWP-2222',
    name: 'Identification and Positioning',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Identification and Positioning (Product SWP-2222)'
  },
  {
    id: 'SWP-2236',
    name: 'Product for technical Domain Enabling in OtD',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Product for technical Domain Enabling in OtD (Product SWP-2236)'
  },
  {
    id: 'SWP-2237',
    name: 'Product for Regional Domain Americas',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Product for Regional Domain Americas (Product SWP-2237)'
  },
  {
    id: 'SWP-2238',
    name: 'Product for Regional Domain APAC',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Product for Regional Domain APAC (Product SWP-2238)'
  },
  {
    id: 'SWP-2239',
    name: 'Product for Regional Domain China',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Product for Regional Domain China (Product SWP-2239)'
  },
  {
    id: 'SWP-2240',
    name: 'Product for Regional Domain EMEA',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Product for Regional Domain EMEA (Product SWP-2240)'
  },
  {
    id: 'SWP-2260',
    name: 'Production and Sales Volume Planning',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Production and Sales Volume Planning (Product SWP-2260)'
  },
  {
    id: 'SWP-2281',
    name: 'Dragon RCM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Dragon RCM (Product SWP-2281)'
  },
  {
    id: 'SWP-2282',
    name: 'Seal RCM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Seal RCM (Product SWP-2282)'
  },
  {
    id: 'SWP-2283',
    name: 'MRI',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'MRI (Product SWP-2283)'
  },
  {
    id: 'SWP-2285',
    name: 'Dragon CF',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Dragon CF (Product SWP-2285)'
  },
  {
    id: 'SWP-2286',
    name: 'Shared CF',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Shared CF (Product SWP-2286)'
  },
  {
    id: 'SWP-2287',
    name: 'Banking DE',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Banking DE (Product SWP-2287)'
  },
  {
    id: 'SWP-2288',
    name: 'Insurance (non-vehicle)',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Insurance (non-vehicle) (Product SWP-2288)'
  },
  {
    id: 'SWP-2290',
    name: 'FS Document Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'FS Document Management (Product SWP-2290)'
  },
  {
    id: 'SWP-2295',
    name: 'Decentral Ordering',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Decentral Ordering (Product SWP-2295)'
  },
  {
    id: 'SWP-2296',
    name: 'Rolls-Royce SAP Template',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Rolls-Royce SAP Template (Product SWP-2296)'
  },
  {
    id: 'SWP-2297',
    name: 'Vehicle Dispatch & Distribution',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Vehicle Dispatch & Distribution (Product SWP-2297)'
  },
  {
    id: 'SWP-2326',
    name: 'Retail Contract Self Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Retail Contract Self Services (Product SWP-2326)'
  },
  {
    id: 'SWP-2327',
    name: 'Retail Portals & Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Retail Portals & Services (Product SWP-2327)'
  },
  {
    id: 'SWP-2333',
    name: 'Retail Application Management M/S Markets',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Retail Application Management M/S Markets (Product SWP-2333)'
  },
  {
    id: 'SWP-2336',
    name: 'Retail Contract Management M/S Markets',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Retail Contract Management M/S Markets (Product SWP-2336)'
  },
  {
    id: 'SWP-2339',
    name: 'Commercial Finance M/S Markets',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Commercial Finance M/S Markets (Product SWP-2339)'
  },
  {
    id: 'SWP-2350',
    name: 'Visual Analytics for Big Data & AI',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Visual Analytics for Big Data & AI (Product SWP-2350)'
  },
  {
    id: 'SWP-2363',
    name: 'Regulated EU Financial Steering',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Regulated EU Financial Steering (Product SWP-2363)'
  },
  {
    id: 'SWP-2364',
    name: 'Regulated EU Risk Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Regulated EU Risk Management (Product SWP-2364)'
  },
  {
    id: 'SWP-2420',
    name: 'BizDevOps Transformation',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'BizDevOps Transformation (Product SWP-2420)'
  },
  {
    id: 'SWP-2421',
    name: 'Cyber Security, Digital Identity',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Cyber Security, Digital Identity (Product SWP-2421)'
  },
  {
    id: 'SWP-2429',
    name: 'Agile Working Model (AWM)',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Agile Working Model (AWM) (Product SWP-2429)'
  },
  {
    id: 'SWP-2433',
    name: 'IT Strategy, Product Portfolio',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'IT Strategy, Product Portfolio (Product SWP-2433)'
  },
  {
    id: 'SWP-2437',
    name: 'IT Architecture, Innovation, Technology',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'IT Architecture, Innovation, Technology (Product SWP-2437)'
  },
  {
    id: 'SWP-2440',
    name: 'Geometrical Integration',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Geometrical Integration (Product SWP-2440)'
  },
  {
    id: 'SWP-2442',
    name: 'Design and Virtual Product Experience',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Design and Virtual Product Experience (Product SWP-2442)'
  },
  {
    id: 'SWP-2485',
    name: 'Blockchain Technology',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Blockchain Technology (Product SWP-2485)'
  },
  {
    id: 'SWP-2486',
    name: 'IoT and Sensor Data Provenance',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'IoT and Sensor Data Provenance (Product SWP-2486)'
  },
  {
    id: 'SWP-2487',
    name: 'DLT Marketplace',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'DLT Marketplace (Product SWP-2487)'
  },
  {
    id: 'SWP-2553',
    name: 'Warranty & Technical Campaign Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Warranty & Technical Campaign Management (Product SWP-2553)'
  },
  {
    id: 'SWP-2810',
    name: 'Open Data Platform',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Open Data Platform (Product SWP-2810)'
  },
  {
    id: 'SWP-2488',
    name: 'Emission Services (OtO/DtCC)',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Emission Services (OtO/DtCC) (Product SWP-2488)'
  },
  {
    id: 'SWP-2519',
    name: 'Platform Services Autonomous Driving',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Platform Services Autonomous Driving (Product SWP-2519)'
  },
  {
    id: 'SWP-2533',
    name: 'Location Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Location Services (Product SWP-2533)'
  },
  {
    id: 'SWP-2811',
    name: 'Big Data Ingress & Synchronization',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Big Data Ingress & Synchronization (Product SWP-2811)'
  },
  {
    id: 'SWP-2552',
    name: 'Quality Reporting, Analytics & Targets Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Quality Reporting, Analytics & Targets Management (Product SWP-2552)'
  },
  {
    id: 'SWP-2582',
    name: 'Collaboration Workspace',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Collaboration Workspace (Product SWP-2582)'
  },
  {
    id: 'SWP-2583',
    name: 'Workspace Service',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Workspace Service (Product SWP-2583)'
  },
  {
    id: 'SWP-2584',
    name: 'Mobile Workspace',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Mobile Workspace (Product SWP-2584)'
  },
  {
    id: 'SWP-2585',
    name: 'Personal Workspace',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Personal Workspace (Product SWP-2585)'
  },
  {
    id: 'SWP-2591',
    name: 'Websites - Inspire to Commit (Group, Motorrad & RR)',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Websites - Inspire to Commit (Group, Motorrad & RR) (Product SWP-2591)'
  },
  {
    id: 'SWP-2592',
    name: 'Web Frontend Services (BMW & MINI)',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Web Frontend Services (BMW & MINI) (Product SWP-2592)'
  },
  {
    id: 'SWP-2613',
    name: 'Business Mobility Customer Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Business Mobility Customer Services (Product SWP-2613)'
  },
  {
    id: 'SWP-2614',
    name: 'Business Mobility Contract Lifecycle',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Business Mobility Contract Lifecycle (Product SWP-2614)'
  },
  {
    id: 'SWP-2615',
    name: 'Business Mobility Asset Chain',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Business Mobility Asset Chain (Product SWP-2615)'
  },
  {
    id: 'SWP-2616',
    name: 'Business Mobility Mobility Solutions',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Business Mobility Mobility Solutions (Product SWP-2616)'
  },
  {
    id: 'SWP-2617',
    name: 'Business Mobility Asset Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Business Mobility Asset Management (Product SWP-2617)'
  },
  {
    id: 'SWP-2618',
    name: 'Business Mobility Risk Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Business Mobility Risk Management (Product SWP-2618)'
  },
  {
    id: 'SWP-2619',
    name: 'Business Mobility Shared Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Business Mobility Shared Services (Product SWP-2619)'
  },
  {
    id: 'SWP-2620',
    name: 'Business Mobility Target Reference Solution',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Business Mobility Target Reference Solution (Product SWP-2620)'
  },
  {
    id: 'SWP-2656',
    name: 'Material Master, Vehicle Orders / Consumption in Parts Logistics',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Material Master, Vehicle Orders / Consumption in Parts Logistics (Product SWP-2656)'
  },
  {
    id: 'SWP-2657',
    name: 'Supplier Call Off and Supply Chain Control',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Supplier Call Off and Supply Chain Control (Product SWP-2657)'
  },
  {
    id: 'SWP-2664',
    name: 'HR & HC Planning & Reporting',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'HR & HC Planning & Reporting (Product SWP-2664)'
  },
  {
    id: 'SWP-2672',
    name: 'Shared Dev Chain Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Shared Dev Chain Services (Product SWP-2672)'
  },
  {
    id: 'SWP-2679',
    name: 'Travel',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Travel (Product SWP-2679)'
  },
  {
    id: 'SWP-2680',
    name: 'Accounts Payable  / Freight',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Accounts Payable  / Freight (Product SWP-2680)'
  },
  {
    id: 'SWP-2681',
    name: 'Accounts Receivable',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Accounts Receivable (Product SWP-2681)'
  },
  {
    id: 'SWP-2682',
    name: 'Inventory Accounting',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Inventory Accounting (Product SWP-2682)'
  },
  {
    id: 'SWP-2684',
    name: 'Asset Accounting',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Asset Accounting (Product SWP-2684)'
  },
  {
    id: 'SWP-2686',
    name: 'International Finance Template',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'International Finance Template (Product SWP-2686)'
  },
  {
    id: 'SWP-2687',
    name: 'General Ledger',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'General Ledger (Product SWP-2687)'
  },
  {
    id: 'SWP-2690',
    name: 'OtD Messaging',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'OtD Messaging (Product SWP-2690)'
  },
  {
    id: 'SWP-2697',
    name: 'OtD Services and Solutions',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'OtD Services and Solutions (Product SWP-2697)'
  },
  {
    id: 'SWP-2706',
    name: 'Group Reporting',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Group Reporting (Product SWP-2706)'
  },
  {
    id: 'SWP-2707',
    name: 'Product Cost & Inventory',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Product Cost & Inventory (Product SWP-2707)'
  },
  {
    id: 'SWP-2708',
    name: 'Business Process Mgmt.',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Business Process Mgmt. (Product SWP-2708)'
  },
  {
    id: 'SWP-2709',
    name: 'Periodical Steering',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Periodical Steering (Product SWP-2709)'
  },
  {
    id: 'SWP-2710',
    name: 'Supplier Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Supplier Management (Product SWP-2710)'
  },
  {
    id: 'SWP-2711',
    name: 'FS IT Information Protection',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'FS IT Information Protection (Product SWP-2711)'
  },
  {
    id: 'SWP-2712',
    name: 'FS I&AM',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'FS I&AM (Product SWP-2712)'
  },
  {
    id: 'SWP-2713',
    name: 'FS Data Protection, Audit & IT ICS',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'FS Data Protection, Audit & IT ICS (Product SWP-2713)'
  },
  {
    id: 'SWP-2718',
    name: 'Dealer Sales Assistants',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Dealer Sales Assistants (Product SWP-2718)'
  },
  {
    id: 'SWP-2719',
    name: 'Retail Sales Portal',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Retail Sales Portal (Product SWP-2719)'
  },
  {
    id: 'SWP-2733',
    name: 'Global integr. Risk & Finance Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Global integr. Risk & Finance Management (Product SWP-2733)'
  },
  {
    id: 'SWP-2736',
    name: 'Local Risk & Finance Management.',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Local Risk & Finance Management. (Product SWP-2736)'
  },
  {
    id: 'SWP-2740',
    name: 'Network Datacenter, Defense',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Network Datacenter, Defense (Product SWP-2740)'
  },
  {
    id: 'SWP-2741',
    name: 'Network Health, Automation',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Network Health, Automation (Product SWP-2741)'
  },
  {
    id: 'SWP-2742',
    name: 'IT Infrastructure for Construction',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'IT Infrastructure for Construction (Product SWP-2742)'
  },
  {
    id: 'SWP-2743',
    name: 'Scheduling, Filetransfer',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Scheduling, Filetransfer (Product SWP-2743)'
  },
  {
    id: 'SWP-2746',
    name: 'Database',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Database (Product SWP-2746)'
  },
  {
    id: 'SWP-2750',
    name: 'HPC',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'HPC (Product SWP-2750)'
  },
  {
    id: 'SWP-2751',
    name: 'Corporate Service Document and Output Management',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Corporate Service Document and Output Management (Product SWP-2751)'
  },
  {
    id: 'SWP-2804',
    name: 'AI Building Blocks & Data Science Methods',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'AI Building Blocks & Data Science Methods (Product SWP-2804)'
  },
  {
    id: 'SWP-2807',
    name: 'Big Data, AI Platforms',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Big Data, AI Platforms (Product SWP-2807)'
  },
  {
    id: 'SWP-2808',
    name: 'Cloud Data, AI Platform',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Cloud Data, AI Platform (Product SWP-2808)'
  },
  {
    id: 'SWP-2778',
    name: 'Long Range Planning',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Long Range Planning (Product SWP-2778)'
  },
  {
    id: 'SWP-2784',
    name: 'NLP & Semantic Search',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'NLP & Semantic Search (Product SWP-2784)'
  },
  {
    id: 'SWP-2787',
    name: 'Data Science, Analytics Connected Vehicle',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Science, Analytics Connected Vehicle (Product SWP-2787)'
  },
  {
    id: 'SWP-2788',
    name: 'Data Science, Analytics  ItO',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Science, Analytics  ItO (Product SWP-2788)'
  },
  {
    id: 'SWP-2789',
    name: 'Data Science, Analytics  Enabling',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Science, Analytics  Enabling (Product SWP-2789)'
  },
  {
    id: 'SWP-2790',
    name: 'Data Science, Analytics  OtD',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Science, Analytics  OtD (Product SWP-2790)'
  },
  {
    id: 'SWP-2791',
    name: 'Data Science, Analytics Quality',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Science, Analytics Quality (Product SWP-2791)'
  },
  {
    id: 'SWP-2792',
    name: 'Data Science, Analytics Oto/DtCC',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Science, Analytics Oto/DtCC (Product SWP-2792)'
  },
  {
    id: 'SWP-2793',
    name: 'Data Science, Analytics Financial Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Science, Analytics Financial Services (Product SWP-2793)'
  },
  {
    id: 'SWP-2795',
    name: 'Assisted Self-Service for Data Science',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Assisted Self-Service for Data Science (Product SWP-2795)'
  },
  {
    id: 'SWP-2797',
    name: 'Data Asset  Connected Vehicle',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Asset  Connected Vehicle (Product SWP-2797)'
  },
  {
    id: 'SWP-2798',
    name: 'Data Asset  OtD Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Asset  OtD Data (Product SWP-2798)'
  },
  {
    id: 'SWP-2799',
    name: 'Data Asset  Market Customer Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Asset  Market Customer Data (Product SWP-2799)'
  },
  {
    id: 'SWP-2800',
    name: 'Data Asset  Enabling Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Asset  Enabling Data (Product SWP-2800)'
  },
  {
    id: 'SWP-2801',
    name: 'Data Asset  ItO, Quality Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Asset  ItO, Quality Data (Product SWP-2801)'
  },
  {
    id: 'SWP-2802',
    name: 'Data Asset  OtO, DtCC Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Asset  OtO, DtCC Data (Product SWP-2802)'
  },
  {
    id: 'SWP-2803',
    name: 'Data Asset  Financial Services Data',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Data Asset  Financial Services Data (Product SWP-2803)'
  },
  {
    id: 'SWP-2813',
    name: 'Cloud Native Building Blocks',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Cloud Native Building Blocks (Product SWP-2813)'
  },
  {
    id: 'SWP-2814',
    name: 'Public Cloud Platform & Hybridization',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Public Cloud Platform & Hybridization (Product SWP-2814)'
  },
  {
    id: 'SWP-2815',
    name: 'DevOps Excellence',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'DevOps Excellence (Product SWP-2815)'
  },
  {
    id: 'SWP-2819',
    name: 'On-Premise Cloud Platform',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'On-Premise Cloud Platform (Product SWP-2819)'
  },
  {
    id: 'SWP-2833',
    name: 'Connected Aftersales Services',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Connected Aftersales Services (Product SWP-2833)'
  },
  {
    id: 'SWP-2836',
    name: 'Tax',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Tax (Product SWP-2836)'
  },
  {
    id: 'SWP-2837',
    name: 'Finance Integration',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Finance Integration (Product SWP-2837)'
  },
  {
    id: 'SWP-2838',
    name: 'Customs',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Customs (Product SWP-2838)'
  },
  {
    id: 'SWP-2866',
    name: 'ERP Platforms (Enabling)',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'ERP Platforms (Enabling) (Product SWP-2866)'
  },
  {
    id: 'SWP-2867',
    name: 'Compensation & Benefits, Working Time, Job Evaluation',
    status: 'Published',
    type: 'Product',
    children: null,
    displayName: 'Compensation & Benefits, Working Time, Job Evaluation (Product SWP-2867)'
  }
];
