import {Component, Input, NgZone, OnInit} from '@angular/core';
import {ProductModel} from '@interface/models';
import {NgRedux} from '@angular-redux/store';
import {RootState} from '@store/reducer/root-reducer';

@Component({
  selector: 'app-product-dropdown',
  templateUrl: './product-dropdown.component.html',
  styleUrls: ['./product-dropdown.component.scss']
})
export class ProductDropdownComponent implements OnInit {
  @Input() dropdownContainerOuterClasses = '';
  @Input() dropdownContainerInnerClasses = '';
  @Input() isVisible = false;

  @Input()
  set products(value: ProductModel[]) {
    this.products$ = value;
  }

  private filter = '';
  private products$: ProductModel[] = [];

  private get filteredProducts() {
    return this.filterProducts(this.filter);
  }

  constructor(private zone: NgZone, private redux: NgRedux<RootState>) {
  }

  ngOnInit() {

  }

  filterProducts(text: string): ProductModel[] {
    return this.products$.filter(i => i.displayName.toLowerCase().includes(text.toLowerCase()));
  }

  updateFilter(filter: string) {
    this.zone.run(() => {
      this.filter = filter;
    });
  }

}
