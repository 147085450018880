import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ReplaySubject, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-header-bottom',
  templateUrl: './header-bottom.component.html',
  styleUrls: ['./header-bottom.component.scss']
})
export class HeaderBottomComponent implements OnInit, OnDestroy {
  @HostBinding('class.container-fluid') containerClass = true;

  private routeSubscription: Subscription;
  private currentProductId = new ReplaySubject<string>();

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.routeSubscription = this.route.paramMap.subscribe(value => {
      const productId = value.get('productId');
      console.log(productId);
      if (productId) {
        this.currentProductId.next(productId);
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
}
