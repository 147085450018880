import {UsersModel} from '@interface/models';
import {Dispatch} from 'redux';
import {FetchingAction} from '@store/shared/fetching-reducer';
import {fakeFetch, storeKey} from '@common/helpers';
import {SampleUsers} from '@sample/sample-users';
import {RootState} from '@store/reducer/root-reducer';

interface AddUsersAction {
  type: 'ADD_USERS';
  data: UsersModel;
  projectKey: string;
}

interface DeleteUsersAction {
  type: 'DELETE_USERS';
  userKeys: string[];
  projectKey: string;
}

export type UserAction = AddUsersAction | DeleteUsersAction;

export const loadProjectUsers = (projectKey: string,
                                 force: boolean = false) => async (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState().model.users.items;
  if (state[storeKey(projectKey)] && !force) {
    return;
  }

  try {
    dispatch<FetchingAction>({type: 'START_READ_USERS'});
    const data = await fakeFetch(SampleUsers);
    dispatch<AddUsersAction>({type: 'ADD_USERS', data, projectKey});
    dispatch<FetchingAction>({type: 'DONE_READ_USERS'});
  } catch (e) {
    dispatch<FetchingAction>({type: 'ERROR_READ_USERS', error: e});
  }
};

export const createProjectUsers = (projectKey: string, usersKeys: string[]) => async (dispatch: Dispatch) => {
  try {
    dispatch<FetchingAction>({type: 'START_CREATE_USERS'});
    const users = usersKeys.reduce((previousValue, currentValue) => {
      previousValue[currentValue] = 'New Name';
      return previousValue;
    }, {});
    dispatch<AddUsersAction>({type: 'ADD_USERS', data: users, projectKey});
    dispatch<FetchingAction>({type: 'DONE_CREATE_USERS'});
  } catch (e) {
    dispatch<FetchingAction>({type: 'ERROR_CREATE_USERS', error: e});
  }
};

export const deleteProjectUser = (projectKey: string, userKey: string) => async (dispatch: Dispatch) => {
  try {
    dispatch<FetchingAction>({type: 'START_DELETE_USERS'});
    dispatch<DeleteUsersAction>({type: 'DELETE_USERS', projectKey, userKeys: [userKey]});
    dispatch<FetchingAction>({type: 'DONE_DELETE_USERS'});
  } catch (e) {
    dispatch<FetchingAction>({type: 'ERROR_DELETE_USERS', error: e});
  }
}
