// Binds to Product
import {WorkspaceModel} from '@interface/models';

export const SampleWorkspace: WorkspaceModel = {
  nexus: [
    {
      name: 'atcatlassianoperations',
      key: 'atcatlassianoperations',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcatlassianoperations-repositories'
    },
    {
      name: 'atcaddonexportshare',
      key: 'atcaddonexportshare',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcaddonexportshare-repositories'
    },
    {
      name: 'atcpythondemo',
      key: 'atcpythondemo',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcpythondemo-repositories'
    },
    {
      name: 'atcssp',
      key: 'atcssp',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcssp-repositories'
    },
    {
      name: 'atcaddoncustomerfb',
      key: 'atcaddoncustomerfb',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcaddoncustomerfb-repositories'
    },
    {
      name: 'atcday',
      key: 'atcday',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcday-repositories'
    },
    {
      name: 'atcrestitm',
      key: 'atcrestitm',
      url: 'https://itm-ci.bmwgroup.net/nexus/#browse/browse:atcrestitm-repositories'
    },
    {
      name: 'atcrest',
      key: 'atcrest',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcrest-repositories'
    },
    {
      name: 'atcaddonuiconfluence',
      key: 'atcaddonuiconfluence',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcaddonuiconfluence-repositories'
    },
    {
      name: 'atcaddonbbaclearing',
      key: 'atcaddonbbaclearing',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcaddonbbaclearing-repositories'
    },
    {
      name: 'atcaddonitsmsynch',
      key: 'atcaddonitsmsynch',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcaddonitsmsynch-repositories'
    },
    {
      name: 'atcaddon',
      key: 'atcaddon',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcaddon-repositories'
    },
    {
      name: 'atccibasictraining',
      key: 'atccibasictraining',
      url: 'https://itm-ci.bmwgroup.net/nexus/#browse/browse:atccibasictraining-repositories'
    },
    {
      name: 'atcdayoto',
      key: 'atcdayoto',
      url: 'https://oto-ci.bmwgroup.net/nexus/#browse/browse:atcdayoto-repositories'
    },
    {
      name: 'ataplab',
      key: 'ataplab',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:ataplab-repositories'
    },
    {
      name: 'atcatlassian',
      key: 'atcatlassian',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcatlassian-repositories'
    },
    {
      name: 'atcaddonjiracascading',
      key: 'atcaddonjiracascading',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcaddonjiracascading-repositories'
    },
    {
      name: 'atcdemo',
      key: 'atcdemo',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcdemo-repositories'
    },
    {
      name: 'atcaddonsolman',
      key: 'atcaddonsolman',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcaddonsolman-repositories'
    },
    {
      name: 'atcrest',
      key: 'atcrest',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcrest-repositories'
    },
    {
      name: 'atcrest',
      key: 'atcrest',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcrest-repositories'
    },
    {
      name: 'atcaddontermsandcond',
      key: 'atcaddontermsandcond',
      url: 'https://ito-ci.bmwgroup.net/nexus/#browse/browse:atcaddontermsandcond-repositories'
    }
  ],
  confluence: [
    {
      name: 'ATC Tenders',
      key: 'ATCTENDERS',
      url: 'https://atc.bmwgroup.net/confluence/display/ATCTENDERS'
    },
    {
      name: 'ATC Open Space',
      key: 'AOS',
      url: 'https://atc.bmwgroup.net/confluence/display/AOS'
    },
    {
      name: 'ATC Internal Documentation',
      key: 'ATC',
      url: 'https://atc.bmwgroup.net/confluence/display/ATC'
    },
    {
      name: 'ATC Workshop Product',
      key: 'ATCDAY',
      url: 'https://atc.bmwgroup.net/confluence/display/ATCDAY'
    },
    {
      name: 'ATC Operations',
      key: 'ATCOP',
      url: 'https://atc.bmwgroup.net/confluence/display/ATCOP'
    }
  ],
  jenkins: [
    {
      name: 'atcatlassianoperations',
      key: 'atcatlassianoperations',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcatlassianoperations'
    },
    {
      name: 'atcaddonexportshare',
      key: 'atcaddonexportshare',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcaddonexportshare'
    },
    {
      name: 'atcpythondemo',
      key: 'atcpythondemo',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcpythondemo'
    },
    {
      name: 'atcssp',
      key: 'atcssp',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcssp'
    },
    {
      name: 'atcaddoncustomerfb',
      key: 'atcaddoncustomerfb',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcaddoncustomerfb'
    },
    {
      name: 'atcday',
      key: 'atcday',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcday'
    },
    {
      name: 'atcrestitm',
      key: 'atcrestitm',
      url: 'https://itm-ci.bmwgroup.net/jenkins/job/atcrestitm'
    },
    {
      name: 'atcrest',
      key: 'atcrest',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcrest'
    },
    {
      name: 'atcaddonuiconfluence',
      key: 'atcaddonuiconfluence',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcaddonuiconfluence'
    },
    {
      name: 'atcaddonbbaclearing',
      key: 'atcaddonbbaclearing',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcaddonbbaclearing'
    },
    {
      name: 'atcaddonitsmsynch',
      key: 'atcaddonitsmsynch',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcaddonitsmsynch'
    },
    {
      name: 'atcaddon',
      key: 'atcaddon',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcaddon'
    },
    {
      name: 'atccibasictraining',
      key: 'atccibasictraining',
      url: 'https://itm-ci.bmwgroup.net/jenkins/job/atccibasictraining'
    },
    {
      name: 'atcdayoto',
      key: 'atcdayoto',
      url: 'https://oto-ci.bmwgroup.net/jenkins/job/atcdayoto'
    },
    {
      name: 'ataplab',
      key: 'ataplab',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/ataplab'
    },
    {
      name: 'atcatlassian',
      key: 'atcatlassian',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcatlassian'
    },
    {
      name: 'atcaddonjiracascading',
      key: 'atcaddonjiracascading',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcaddonjiracascading'
    },
    {
      name: 'atcdemo',
      key: 'atcdemo',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcdemo'
    },
    {
      name: 'atcaddonsolman',
      key: 'atcaddonsolman',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcaddonsolman'
    },
    {
      name: 'atcrest',
      key: 'atcrest',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcrest'
    },
    {
      name: 'atcrest',
      key: 'atcrest',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcrest'
    },
    {
      name: 'atcaddontermsandcond',
      key: 'atcaddontermsandcond',
      url: 'https://ito-ci.bmwgroup.net/jenkins/job/atcaddontermsandcond'
    }
  ],
  sonar: [
    {
      name: 'atcatlassianoperations',
      key: 'atcatlassianoperations',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcatlassianoperations'
    },
    {
      name: 'atcaddonexportshare',
      key: 'atcaddonexportshare',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcaddonexportshare'
    },
    {
      name: 'atcpythondemo',
      key: 'atcpythondemo',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcpythondemo'
    },
    {
      name: 'atcssp',
      key: 'atcssp',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcssp'
    },
    {
      name: 'atcaddoncustomerfb',
      key: 'atcaddoncustomerfb',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcaddoncustomerfb'
    },
    {
      name: 'atcday',
      key: 'atcday',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcday'
    },
    {
      name: 'atcrestitm',
      key: 'atcrestitm',
      url: 'https://itm-ci.bmwgroup.net/sonar/projects?search=atcrestitm'
    },
    {
      name: 'atcrest',
      key: 'atcrest',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcrest'
    },
    {
      name: 'atcaddonuiconfluence',
      key: 'atcaddonuiconfluence',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcaddonuiconfluence'
    },
    {
      name: 'atcaddonbbaclearing',
      key: 'atcaddonbbaclearing',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcaddonbbaclearing'
    },
    {
      name: 'atcaddonitsmsynch',
      key: 'atcaddonitsmsynch',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcaddonitsmsynch'
    },
    {
      name: 'atcaddon',
      key: 'atcaddon',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcaddon'
    },
    {
      name: 'atccibasictraining',
      key: 'atccibasictraining',
      url: 'https://itm-ci.bmwgroup.net/sonar/projects?search=atccibasictraining'
    },
    {
      name: 'atcdayoto',
      key: 'atcdayoto',
      url: 'https://oto-ci.bmwgroup.net/sonar/projects?search=atcdayoto'
    },
    {
      name: 'ataplab',
      key: 'ataplab',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=ataplab'
    },
    {
      name: 'atcatlassian',
      key: 'atcatlassian',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcatlassian'
    },
    {
      name: 'atcaddonjiracascading',
      key: 'atcaddonjiracascading',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcaddonjiracascading'
    },
    {
      name: 'atcdemo',
      key: 'atcdemo',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcdemo'
    },
    {
      name: 'atcaddonsolman',
      key: 'atcaddonsolman',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcaddonsolman'
    },
    {
      name: 'atcrest',
      key: 'atcrest',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcrest'
    },
    {
      name: 'atcrest',
      key: 'atcrest',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcrest'
    },
    {
      name: 'atcaddontermsandcond',
      key: 'atcaddontermsandcond',
      url: 'https://ito-ci.bmwgroup.net/sonar/projects?search=atcaddontermsandcond'
    }
  ],
  bitbucket: [
    {
      name: 'ATC Open Source',
      key: 'AOS',
      url: 'https://atc.bmwgroup.net/bitbucket/projects/AOS'
    },
    {
      name: 'ATC Internal',
      key: 'ATCI',
      url: 'https://atc.bmwgroup.net/bitbucket/projects/ATCI'
    },
    {
      name: 'ATC Operations',
      key: 'ATCOP',
      url: 'https://atc.bmwgroup.net/bitbucket/projects/ATCOP'
    }
  ],
  jira: [
    {
      name: 'ATC Tender',
      key: 'ATCTENDER',
      url: 'https://atc.bmwgroup.net/jira/projects/ATCTENDER'
    },
    {
      name: 'ATC Open Space',
      key: 'AOS',
      url: 'https://atc.bmwgroup.net/jira/projects/AOS'
    },
    {
      name: 'ATC Testprojekt / "Spielwiese"',
      key: 'TP',
      url: 'https://atc.bmwgroup.net/jira/projects/TP'
    },
    {
      name: 'ATC Operations',
      key: 'ATCOP',
      url: 'https://atc.bmwgroup.net/jira/projects/ATCOP'
    },
    {
      name: 'ATC Workshop Product',
      key: 'ATCDAY',
      url: 'https://atc.bmwgroup.net/jira/projects/ATCDAY'
    },
    {
      name: 'ATC Internal',
      key: 'ATCI',
      url: 'https://atc.bmwgroup.net/jira/projects/ATCI'
    }
  ]
};
