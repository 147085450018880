import {WorkspaceModelState} from '@interface/state';
import {WorkspaceAction} from '@store/action/model/workspace-action';
import {pipeReducers, storeKey} from '@common/helpers';
import {fetchingReducer} from '@store/shared/fetching-reducer';

const INITIAL_STATE: WorkspaceModelState = {
  items: {},
  status: {
    done: [],
    pending: [],
    error: {}
  }
};

const workspaceModelReducer = (state: WorkspaceModelState = INITIAL_STATE, action: WorkspaceAction): WorkspaceModelState => {
  switch (action.type) {
    case 'ADD_WORKSPACES':
      return {
        ...state,
        items: {
          ...state.items,
          [storeKey(action.productId)]: {
            data: {
              ...action.data
            },
            productId: action.productId
          }
        }
      };
    default:
      return state;
  }
};

const workspaceReducer = pipeReducers(
  workspaceModelReducer,
  fetchingReducer('WORKSPACES', 'READ')
);

export default workspaceReducer;
