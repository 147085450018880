import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {TweenMax} from 'gsap';

export interface OutlineData {
  width: string;
  style: string;
  color: {
    active: string,
    inactive: string
  };
}

@Directive({
  selector: '[appOutlineActive]'
})
export class OutlineActiveDirective implements OnInit {
  @Input() outlineDefaults: OutlineData;

  @Input()
  set outlineActive(outline: boolean) {
    const {color} = this.outlineDefaults;
    TweenMax.to(this.elementRef.nativeElement, 0.35, {
      outlineColor: outline ? color.active : color.inactive
    });
  }

  constructor(private elementRef: ElementRef<HTMLElement>) {
  }

  ngOnInit(): void {
    const {style} = this.elementRef.nativeElement;
    style.outlineStyle = this.outlineDefaults.style;
    style.outlineWidth = this.outlineDefaults.width;
    style.outlineColor = this.outlineDefaults.color.inactive;
  }
}
