import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {NgRedux, NgReduxModule} from '@angular-redux/store';
import {RootState, store} from '@store/reducer/root-reducer';
import {HeaderComponent} from './layout/header/header.component';
import {BorderButtonComponent} from './components/button/border-button/border-button.component';
import {HeaderTopComponent} from './layout/header/header-top/header-top.component';
import {HeaderBottomComponent} from './layout/header/header-bottom/header-bottom.component';
import {HeaderButtonComponent} from './components/button/header-button/header-button.component';
import {ActionToolbarComponent} from './components/common/action-toolbar/action-toolbar.component';
import {ToolbarButtonComponent} from './components/button/toolbar-button/toolbar-button.component';
import {FooterComponent} from './layout/footer/footer.component';
import {ProductComponent} from './page/product/product.component';
import {ApplicationCardComponent} from './components/application/application-card/application-card.component';
import {RoundIconComponent} from './components/common/round-icon/round-icon.component';
import {BasicButtonComponent} from './components/button/basic-button/basic-button.component';
import {ApplicationCardSectionComponent} from './components/application/application-card-section/application-card-section.component';
import {WorkspaceGridComponent} from './parts/product/workspace-grid/workspace-grid.component';
import {SeparatorComponent} from './components/common/separator/separator.component';
import {IconHeadingComponent} from './components/common/icon-heading/icon-heading.component';
import {NumberFormatPipePipe} from './pipes/number-format-pipe.pipe';
import {OutlineActiveDirective} from './directives/outline-active.directive';
import {ClickPropagationStopDirective} from './directives/click-propagation-stop.directive';
import {NgxPrettyCheckboxModule} from 'ngx-pretty-checkbox';
import {HoverListenerDirective} from './directives/hover-listener.directive';
import {ArrowDropdownContainerComponent} from './containers/arrow-dropdown-container/arrow-dropdown-container.component';
import {ArrowContainerDirective} from './directives/arrow-container.directive';
import {ActionDropdownComponent} from './components/dropdown/action-dropdown/action-dropdown.component';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {DropdownButtonComponent} from './components/button/dropdown-button/dropdown-button.component';
import {ActionSelectComponent} from './parts/product/action-select/action-select.component';
import {ProductDropdownComponent} from './components/dropdown/product-dropdown/product-dropdown.component';
import {TextInputComponent} from './components/input/text-input/text-input.component';
import {FormsModule} from '@angular/forms';
import {HeaderProductItemComponent} from './layout/header/header-bottom/header-product-item/header-product-item.component';
import {ApplicationComponent} from './page/application/application.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApplicationMainComponent} from './parts/application/application-main/application-main.component';
import {BackButtonComponent} from './components/button/back-button/back-button.component';
import {ProjectTableComponent} from './components/table/project-table/project-table.component';
import {ApplicationUsersComponent} from './page/application-users/application-users.component';
import {UserManageMainComponent} from './parts/application-users/user-manage-main/user-manage-main.component';
import {ManageUsersComponent} from './parts/application-users/manage-users/manage-users.component';
import {NewUserComponent} from './components/user/new-user/new-user.component';
import {AuthorizedUserComponent} from './components/user/authorized-user/authorized-user.component';
import {AuthorizeUserTableComponent} from './parts/application-users/authorize-user-table/authorize-user-table.component';
import {SlideUpContainerComponent} from './containers/slide-up-container/slide-up-container.component';
import * as Hammer from 'hammerjs';
import {ActionListComponent} from './parts/product/action-list/action-list.component';
import {AuthorizedUserListComponent} from './parts/application-users/authorized-user-list/authorized-user-list.component';
import {DefaultBackgroundComponent} from './components/common/default-background/default-background.component';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {FadeInInitDirective} from './directives/fade-in-init.directive';
import {NgxPaginationModule} from 'ngx-pagination';
import { CustomPaginationControlsComponent } from './components/common/custom-pagination-controls/custom-pagination-controls.component';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

const playerFactory = () => player;

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BorderButtonComponent,
    HeaderTopComponent,
    HeaderBottomComponent,
    HeaderButtonComponent,
    ActionToolbarComponent,
    ToolbarButtonComponent,
    FooterComponent,
    ProductComponent,
    ApplicationCardComponent,
    RoundIconComponent,
    BasicButtonComponent,
    ApplicationCardSectionComponent,
    WorkspaceGridComponent,
    SeparatorComponent,
    IconHeadingComponent,
    NumberFormatPipePipe,
    OutlineActiveDirective,
    ClickPropagationStopDirective,
    HoverListenerDirective,
    ArrowDropdownContainerComponent,
    ArrowContainerDirective,
    ActionDropdownComponent,
    DropdownButtonComponent,
    ActionSelectComponent,
    ProductDropdownComponent,
    TextInputComponent,
    HeaderProductItemComponent,
    ApplicationComponent,
    ApplicationMainComponent,
    BackButtonComponent,
    ProjectTableComponent,
    ApplicationUsersComponent,
    UserManageMainComponent,
    ManageUsersComponent,
    NewUserComponent,
    AuthorizedUserComponent,
    AuthorizeUserTableComponent,
    DefaultBackgroundComponent,
    FadeInInitDirective,
    SlideUpContainerComponent,
    ActionListComponent,
    AuthorizedUserListComponent,
    CustomPaginationControlsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgReduxModule,
    NgxPrettyCheckboxModule,
    NgScrollbarModule,
    FormsModule,
    LottieModule.forRoot({player: playerFactory}),
    NgxPaginationModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(ngRedux: NgRedux<RootState>) {
    ngRedux.provideStore(store);
  }
}
