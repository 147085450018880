import {WorkspaceModel} from '@interface/models';
import {Dispatch} from 'redux';
import {FetchingAction} from '@store/shared/fetching-reducer';
import {fakeFetch, storeKey} from '@common/helpers';
import {SampleWorkspace} from '@sample/sample-workspace';
import {RootState} from '@store/reducer/root-reducer';

interface AddWorkspaceAction {
  type: 'ADD_WORKSPACES';
  data: WorkspaceModel;
  productId: string;
}

export type WorkspaceAction = AddWorkspaceAction;

export const loadProductWorkspace = (productId: string,
                                     force: boolean = false) => async (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState().model.workspaces.items;
  if (state[storeKey(productId)] && !force) {
    return;
  }

  try {
    dispatch<FetchingAction>({type: 'START_READ_WORKSPACES'});
    const data = await fakeFetch(SampleWorkspace);
    dispatch<AddWorkspaceAction>({type: 'ADD_WORKSPACES', data, productId});
    dispatch<FetchingAction>({type: 'DONE_READ_WORKSPACES'});
  } catch (e) {
    dispatch<FetchingAction>({type: 'ERROR_READ_WORKSPACES', error: e});
  }
};
