import {UserModelState} from '@interface/state';
import {UserAction} from '@store/action/model/user-action';
import {fetchingReducer} from '@store/shared/fetching-reducer';
import {pipeReducers, storeKey} from '@common/helpers';
import {omit} from 'lodash';

const INITIAL_STATE: UserModelState = {
  items: {},
  status: {
    pending: [],
    done: [],
    error: {}
  }
};

const usersModelReducer = (state: UserModelState = INITIAL_STATE, action: UserAction): UserModelState => {
  switch (action.type) {
    case 'ADD_USERS': {
      const key = storeKey(action.projectKey);
      return {
        ...state,
        items: {
          ...state.items,
          [key]: {
            data: {
              ...action.data,
              ...(state.items[key] ? state.items[key].data : {})
            },
            projectKey: action.projectKey
          }
        }
      };
    }
    case 'DELETE_USERS': {
      const data = omit(state.items[action.projectKey].data, action.userKeys);
      const key = storeKey(action.projectKey);
      return {
        ...state,
        items: {
          ...state.items,
          [key]: {
            ...state.items[key], data
          }
        }
      };
    }
    default:
      return state;
  }
};

const userReducer = pipeReducers(
  usersModelReducer,
  fetchingReducer('USERS', 'READ'),
  fetchingReducer('USERS', 'CREATE'),
  fetchingReducer('USERS', 'DELETE')
);

export default userReducer;
