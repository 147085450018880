import {AdminModelState} from '@interface/state';
import {AdminAction} from '@store/action/model/admins-action';
import {fetchingReducer} from '@store/shared/fetching-reducer';
import {pipeReducers, storeKey} from '@common/helpers';

const INITIAL_STATE: AdminModelState = {
  items: {},
  status: {
    pending: [],
    done: [],
    error: {}
  }
};

const adminModelReducer = (state: AdminModelState = INITIAL_STATE, action: AdminAction): AdminModelState => {
  switch (action.type) {
    case 'ADD_ADMINS':
      return {
        ...state,
        items: {
          ...state.items,
          [storeKey(action.application, action.projectKey)]: {
            ...action.data,
            application: action.application,
            projectKey: action.projectKey
          }
        }
      };
    default:
      return state;
  }
};

const adminReducer = pipeReducers(
  adminModelReducer,
  fetchingReducer('ADMINS', 'READ')
);

export default adminReducer;
